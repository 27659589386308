import React, { useEffect, useState, useRef } from 'react'
import { obtenerVentaMercadoPago } from '../Utils/MercadoPago'
import { db, obtenerPedidos } from '../Utils/Firebase'
import { collection, getDocs, query, where, doc, updateDoc } from 'firebase/firestore'
import { Table, Button, Form } from "react-bootstrap";
import Modal from 'react-bootstrap/Modal';

function Ventas({ user }) {

    const [show, setShow] = useState(false);

    function handleShow(id) {
        setShow(true);
        setIdModal(id)
    }

    const [ventasConcretadas, setVentasConcretadas] = useState([])
    const [ventasSinConcretar, setVentasSinConcretar] = useState([])

    const [idModal, setIdModal] = useState("")
    const [modalOrderData, setModalOrderData] = useState({})


    const colRef = collection(db, "purchaseOrdersCompleted")
    const colRef2 = collection(db, "purchaseOrders")


    useEffect(() => {

        obtenerPedidos(colRef, setVentasConcretadas)
        obtenerPedidos(colRef2, setVentasSinConcretar)

    }, [])


    const statusLegible = (status) => {
        if (status == "approved") {
            return "Aprobado"
        } else if (status == "in_process") {
            return "En proceso"
        } else if (status == "rejected") {
            return "Rechazado"
        } else {
            return "Desconocido"
        }
    }


    const obtenerPurchase = async () => {
        const q = query(collection(db, "purchaseOrders"), where("payment.id", "==", idModal))

        const querySnapshot = await getDocs(q);

        let order = null

        querySnapshot.forEach((doc) => {
            order = {
                ...doc.data(),
                id: doc.id
            }
        });

        setModalOrderData(order)

    }


    const modificarEstadoDeEntrega = async (id, field, value) => {
        const ref = doc(db, "purchaseOrders", id)
        await updateDoc(ref, {
            [field]: value
        })
    }

    useEffect(() => {

        obtenerPurchase()

    }, [idModal])


    const modalPrecioTotal = (carrito) => {

        let total = 0

        for (let p of carrito) {
            total += p.price * p.amount
        }

        return total
    }


    return (
        <div className='mt-3' style={{ width: "85%", marginInline: "auto" }}>
            <Table striped bordered hover>
                <thead>
                    <tr className='text-light'>
                        <th>Fecha</th>
                        <th>Cuenta que pagó</th>
                        <th>Pedido</th>
                        <th>Estado</th>
                        <th>Estado detalle</th>
                        <th>Cliente Starbene</th>
                    </tr>
                </thead>
                <tbody>
                    {
                        ventasConcretadas?.map((venta, index) => {
                            return (
                                <tr key={index}>
                                    <td style={{ color: "whitesmoke" }}>{new Date(venta.dateCreated).toLocaleString()}</td>
                                    <td style={{ color: "whitesmoke" }}>{venta.first_name && venta.first_name}</td>
                                    <td style={{ color: "whitesmoke" }}>{new Date(venta.dateCreated).toLocaleString()}</td>
                                    <td style={{ color: "whitesmoke" }}>{statusLegible(venta.status)}</td>
                                    <td style={{ color: "whitesmoke" }}>{venta.statusDetail}</td>
                                    <td style={{ color: "whitesmoke" }}><Button variant="light" onClick={() => handleShow(venta.paymentId)}>Ver cliente</Button></td>
                                </tr>
                            )
                        })
                    }
                </tbody>
            </Table>

            {
                modalOrderData &&
                <div>
                    <Modal show={show} fullscreen={"lg-down"} onHide={() => setShow(false)}>
                        <Modal.Header closeButton>
                            <Modal.Title>{modalOrderData.client && modalOrderData.client.fullName}</Modal.Title>
                        </Modal.Header>
                        <Modal.Body>
                            <div>
                                {
                                    modalOrderData.cart && modalOrderData.cart.map((p) => (
                                        <div>
                                            <ul>
                                                <li>{p.product} x {p.amount}</li>
                                            </ul>
                                            <p>Precio por producto: ${p.price}</p>
                                        </div>
                                    ))
                                }
                                <p><strong>Total: ${modalOrderData.cart && modalPrecioTotal(modalOrderData.cart)} </strong></p>
                                <p>Método de entrega de los productos: <strong>{modalOrderData.delivery && modalOrderData.delivery.method}</strong></p>
                            </div>
                            <div>
                                <Button variant='success' onClick={() => modificarEstadoDeEntrega(modalOrderData.id,)}>Concretada</Button>
                            </div>
                        </Modal.Body>
                    </Modal>
                </div>
            }



        </div>
    )
}

export default Ventas