import React from 'react'
import { Button, Form } from 'react-bootstrap'

function EditorDeCampos({ user, campoAEditar, setNuevoValor, confirmarEdicionDeCampo, setInputEdicionTextos, cliente, campoAccion, nuevoValor }) {
    
    return (
        <div className='text-center col-5 mx-auto p-4 bg-smoke radius'>

            <h6>Escriba el nuevo valor de <strong>{campoAEditar.toLowerCase()}</strong></h6>
            <Form.Control type='text' onChange={(e) => setNuevoValor(e.target.value)}>
            </Form.Control>

            <div className='mt-2'>
                <Button className='mx-1' variant='success' onClick={() => {
                    confirmarEdicionDeCampo()
                    setInputEdicionTextos(false)
                }
                }>Confirmar</Button>
                <Button className='mx-1' variant='danger' onClick={() => setInputEdicionTextos(false)}>Cancelar</Button>
            </div>

        </div>
    )
}

export default EditorDeCampos