import React from 'react'
import { Spinner, Button } from 'react-bootstrap'

function LoadingButton({ loading }) {
    return (

        loading ?
            <Spinner animation="border" role="status">
                <span className="visually-hidden">Loading...</span>
            </Spinner>
            :
            <Button
                variant="light"
                type="submit"
                className='my-3'
            >
                Agregar nuevo equipo
            </Button>
    )
}

export default LoadingButton