import React from 'react'
import { Form } from 'react-bootstrap'

function InputForm({ label, field, setNewClient, newClient }) {
    return (
        <Form.Group className="mb-3 col-3 mx-2 bg-smoke p-4 radius">
            <Form.Label className='text-dark'>{label}</Form.Label>
            <Form.Control
                style={{ backgroundColor: 'transparent', borderLeft: 'none', borderRight: 'none', borderTop: 'none' }}
                placeholder={`Ingrese ${label.toLowerCase()}`}
                onChange={(e) =>
                    setNewClient({ ...newClient, [field]: e.target.value })
                }
            />
        </Form.Group>
    )
}

export default InputForm