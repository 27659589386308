import React, { useContext, useEffect } from 'react'
import Navigator from '../../Components/Navigator/Navigator'
import { obtenerNombreUsuario } from '../../Utils/Funciones'
import Card from 'react-bootstrap/Card';
import { AppContext } from '../../Utils/Context'
import { useNavigate } from 'react-router-dom'
import { Icon } from '@iconify/react';
import logo from "../../Utils/Assets/logoStarbene.png"
import "./MenuPrincipal.css"

function MenuPrincipal() {

    const navigate = useNavigate()
    const { user } = useContext(AppContext)

    useEffect(() => {
        !user && navigate("/")
    }, [])

    const botones = [
        {
            titulo: 'Lista de clientes',
            navegacion: '/listaclientes',
            logo: "solar:users-group-two-rounded-bold"
        },
        {
            titulo: 'Lista de equipos',
            navegacion: '/listaequipos',
            logo: 'solar:devices-bold'
        }, {
            titulo: 'Nuevo cliente',
            navegacion: '/nuevocliente',
            logo: 'solar:user-check-rounded-bold-duotone'
        }, {
            titulo: 'Nuevo equipo',
            navegacion: '/nuevoequipo',
            logo: 'solar:add-square-bold'
        },
        // {
        //     titulo: 'Pedidos',
        //     navegacion: '/pedidos',
        //     logo: 'solar:figma-file-bold'
        // }, 
        {
            titulo: 'Noticias',
            navegacion: '/noticias',
            logo: 'solar:wallpaper-linear'

        }, {
            titulo: 'Acciones',
            navegacion: '/acciones',
            logo: 'solar:gallery-edit-bold'
        },
        {
            titulo: 'Notificaciones',
            navegacion: '/notificaciones',
            logo: 'solar:bell-bing-broken'
        },
    ]

    return (
        <div>
            <Navigator />

            <div className='text-center col-8 mx-auto mt-3 banner radius-35 hover-banner'>
                <h3 className='pt-4'>Nos inspiran tus sueños. <br /> Nos une la tecnología.</h3>
                <img src={logo} width="250" />
            </div>

            <div className='col-7 mx-auto'>
                <h2 className='mt-5 text-dark'>¡Hola, {obtenerNombreUsuario(user?.email)}! 👋</h2>
            </div>

            <div className="col-8 mx-auto d-flex flex-wrap text-center justify-content-center">
                {
                    botones.map((btn) => (
                        <Card onClick={() => navigate(btn.navegacion)} className='m-2 shadow hover-card' style={{ borderRadius: 12 }}>
                            <div className="text-center">
                                <Icon icon={btn.logo} color='lightblue' width={150} />
                            </div>
                            <Card.Body>
                                <Card.Title>{btn.titulo}</Card.Title>
                            </Card.Body>
                        </Card>
                    ))
                }

            </div>

        </div>
    )
}

export default MenuPrincipal