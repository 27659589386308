import { createContext, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { onAuthStateChanged } from "firebase/auth"
import { auth } from "./Firebase";

const AppContext = createContext();

const Context = ({ children }) => {
    const navigate = useNavigate();

    const [selected, setSelected] = useState('')
    const [user, setUser] = useState(null);

    useEffect(() => {
        onAuthStateChanged(auth, currentUser => {
            setUser(currentUser);
            localStorage.setItem("user", JSON.stringify(currentUser));
        });
    }, []);

    useEffect(() => {
        const userData = JSON.parse(localStorage.getItem("user"));
        userData ? setUser(userData) : navigate("/")
    }, [localStorage]);

    const data = {
        user,
        selected,
        setSelected
    }

    return <AppContext.Provider value={data}>{children}</AppContext.Provider>;
};

export default Context;
export { AppContext };
