import React, { useState, useEffect } from 'react'
import { Table, Form } from "react-bootstrap";
import Navigator from '../Components/Navigator/Navigator';
import { db, obtenerDocumentos } from '../Utils/Firebase';
import BackMasTitulo from '../Components/BackMasTitulo';
import Searcher from "../Components/Searcher"
import { collection, getDocs, limit, orderBy, query } from 'firebase/firestore';

function Acciones() {

    const [acciones, setAcciones] = useState([])
    const [search, setSearch] = useState("")

    useEffect(() => {
        obtenerAcciones()
    }, [])

    const obtenerAcciones = async () => {
        const q = query(collection(db, "Acciones"), orderBy('fecha', 'desc'), limit(25))
        const querySnapshot = await getDocs(q)
        let datos = []
        querySnapshot.forEach((doc) => {
            datos.push({ ...doc.data(), id: doc.id });
        });
        setAcciones(datos)
    }

    return (
        <div>
            <Navigator />

            <div className="bg-blue col-11 mx-auto p-4 radius mt-5">

                <BackMasTitulo titulo={'Historial de acciones'} />
                <Searcher placeholder='Buscar por objetivo (equipo, número de serie o cliente)' />

                <div className='py-3'>
                    <div className='col-10 mx-auto'>
                        <Table hover className='text-center'>
                            <thead>
                                <tr className='text-smoke text-center'>
                                    <th>Responsable</th>
                                    <th>Fecha</th>
                                    <th>Tipo de acción</th>
                                    <th>Valor anterior</th>
                                    <th>Nuevo valor</th>
                                </tr>
                            </thead>

                            {
                                acciones?.
                                    filter((accion) => accion.nuevoValor.toLowerCase().includes(search.toLowerCase()))

                                    .sort((a, b) => b.fecha?.toDate() - a.fecha?.toDate())

                                    .map((accion, index) => {
                                        return (
                                            <tbody key={index}>
                                                <tr className='text-light'>
                                                    <td>{accion.responsable}</td>
                                                    <td>{accion.fecha.toDate().toLocaleString()}</td>
                                                    <td>{accion.accion}</td>
                                                    <td>{accion.valorAnterior}</td>
                                                    <td>{accion.nuevoValor}</td>
                                                </tr>
                                            </tbody>
                                        )
                                    })}
                        </Table>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default Acciones