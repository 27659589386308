import { Icon } from '@iconify/react'
import React from 'react'

function BotonesPrincipales({handleBtnNuevaNotificacion, handleBtnHistorialNotificaciones }) {
    return (
        <div className='col-7 mx-auto d-flex justify-content-center'>
            <div onClick={handleBtnNuevaNotificacion} className='col-4 p-4 radius bg-smoke m-2 text-center hover-notif pointer'>
                <Icon width={60} icon={'solar:add-square-broken'} color='lightblue' />
                <div className='mt-2'>
                    <strong>Nueva notificación</strong>
                </div>
            </div>
            <div onClick={handleBtnHistorialNotificaciones} className='col-4 p-4 radius bg-smoke m-2 text-center pointer hover-notif'>
                <Icon width={60} icon={'solar:history-2-linear'} color='lightblue' />
                <div className='mt-2'>
                    <strong>Historial de notificaciones</strong>
                </div>
            </div>
        </div>
    )
}

export default BotonesPrincipales