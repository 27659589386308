import React, { useEffect, useState } from 'react'
import { obtenerDocumentos } from '../../Utils/Firebase'
import { Form } from 'react-bootstrap'


function SelectFormEquipos({ newDevice, setNewDevice }) {

    const [equipos, setEquipos] = useState([])

    useEffect(() => {
        obtenerDocumentos("starbeneGeneralDevices", setEquipos)
    }, [])

    return (
        <Form.Group className="m-2">
            <Form.Label className='text-light'>Nombre del equipo</Form.Label>
            <Form.Select
                onChange={(e) =>
                    setNewDevice({ ...newDevice, deviceName: e.target.value })
                }
            >
                <option>Equipo</option>
                {
                    equipos.map((equipo, index) => {
                        return (
                            <option key={index} value={equipo.deviceName}>{equipo.deviceName}</option>
                        )
                    })
                }
            </Form.Select>
        </Form.Group>
    )
}

export default SelectFormEquipos