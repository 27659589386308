import React from 'react'
import { Form } from 'react-bootstrap'

function Searcher({setSearch, placeholder}) {
    return (
        <div className='text-center col-5 mx-auto'>
            <Form.Group className="mb-3">
                <Form.Control type="text" placeholder={placeholder} onChange={(e) => setSearch(e.target.value)} />
            </Form.Group>
        </div>
    )
}

export default Searcher