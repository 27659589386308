export const mensajeIngresoNoAutorizado = "Datos incorrectos ❌. Por favor, intenta de nuevo."

export const botones_navigator = [
    {
        titulo: 'Inicio',
        navegacion: '/menuprincipal',
    },
    {
        titulo: 'Lista de clientes',
        navegacion: '/listaclientes',
    },
    {
        titulo: 'Lista de equipos',
        navegacion: '/listaequipos',
    }, {
        titulo: 'Nuevo cliente',
        navegacion: '/nuevocliente',
    }, {
        titulo: 'Nuevo equipo',
        navegacion: '/nuevoequipo',
    }, {
        titulo: 'Ecommerce',
        navegacion: '/ecommerce',
    }, {
        titulo: 'Noticias',
        navegacion: '/noticias',
    },
]

export const td_tabla_clientes = ['clientFullName', 'clientCountry', 'clientProvince', 'clientCity', 'clientMail', 'clientPhoneContact']
export const th_tabla_clientes = ['Nombre', 'País', 'Provincia', 'Ciudad', 'Mail', 'Teléfono', 'Detalle']
export const th_tabla_equipos = ['Nombre', 'Número de serie', 'Manager', 'Owner', 'Garantía', 'Fecha activación', '']
export const td_tabla_equipos = ['deviceName', 'deviceSerialNumber', 'deviceManager', 'deviceOwner']


export const form_nuevo_cliente = [{
    label: 'Nombre completo',
    field: 'clientFullName',
},
{
    label: 'Correo electrónico',
    field: 'clientMail',
},
{
    label: 'Teléfono',
    field: 'clientPhoneContact',
},
{
    label: 'País',
    field: 'clientCountry',
},
{
    label: 'Provincia',
    field: 'clientProvince',
},
{
    label: 'Ciudad',
    field: 'clientCity',
}
]

export const form_nuevo_equipo = [
    {
        label: 'Número de serie del equipo',
        field: 'deviceSerialNumber'
    },
    {
        label: 'Owner del equipo',
        field: 'deviceOwner'
    }
]


export const clientes_app = [
    null,
    {
        "NOMBRE": "Marcelo",
        "APELLIDO": "Rubini",
        "EQUIPO": "BOLT THREE",
        "N° DE SERIE": 1049,
        "CUIDAD": "ROSARIO",
        "PROVINCIA": "SANTA FE",
        "OWNER": "Marcelo Rubini",
        "MANAGER": "Marcelo Rubini"
    },
    {
        "NOMBRE": "Marcelo",
        "APELLIDO": "Rubini",
        "EQUIPO": "MIO UP NX",
        "N° DE SERIE": 1792,
        "CUIDAD": "ROSARIO",
        "PROVINCIA": "SANTA FE",
        "OWNER": "Marcelo Rubini",
        "MANAGER": "Marcelo Rubini"
    },
    {
        "APELLIDO": "Rubini",
        "EQUIPO": "ALPHA SYGERGY",
        "N° DE SERIE": 1143,
        "CUIDAD": "ROSARIO",
        "PROVINCIA": "SANTA FE",
        "OWNER": "Marcelo Rubini",
        "MANAGER": "Marcelo Rubini"
    },
    {
        "NOMBRE": "Marcelo",
        "APELLIDO": "Rubini",
        "EQUIPO": "ALPHA SYNERGY",
        "N° DE SERIE": 1005,
        "CUIDAD": "ROSARIO",
        "PROVINCIA": "SANTA FE",
        "OWNER": "Gustavo Sammataro",
        "MANAGER": "Marcelo Rubini"
    },
    {
        "NOMBRE": "Marcelo",
        "APELLIDO": "Rubini",
        "EQUIPO": "BOLT THREE",
        "N° DE SERIE": 1038,
        "CUIDAD": "ROSARIO",
        "PROVINCIA": "SANTA FE",
        "OWNER": "Marcelo Rubini",
        "MANAGER": "Marcelo Runini"
    },
    null,
    {
        "NOMBRE": "Maria Natalia",
        "APELLIDO": "Oña",
        "EQUIPO": "MIO UP",
        "N° DE SERIE": 1061,
        "CUIDAD": "ROSARIO",
        "PROVINCIA": "SANTA FE",
        "OWNER": "María Natalia Oña",
        "MANAGER": "María Natalia Oña"
    },
    {
        "NOMBRE": "Maria Natalia",
        "APELLIDO": "Oña",
        "EQUIPO": "MIO UP",
        "N° DE SERIE": 1308,
        "CUIDAD": "ROSARIO",
        "PROVINCIA": "SANTA FE",
        "OWNER": "María Natalia Oña",
        "MANAGER": "María Natalia Oña"
    },
    {
        "NOMBRE": "Maria Natalia",
        "APELLIDO": "Oña",
        "EQUIPO": "MIO UP",
        "N° DE SERIE": 1592,
        "CUIDAD": "ROSARIO",
        "PROVINCIA": "SANTA FE",
        "OWNER": "María Natalia Oña",
        "MANAGER": "María Natalia Oña"
    },
    {
        "NOMBRE": "Maria Natalia",
        "APELLIDO": "Oña",
        "EQUIPO": "ALPHA SYNERGY",
        "N° DE SERIE": 1023,
        "CUIDAD": "ROSARIO",
        "PROVINCIA": "SANTA FE",
        "OWNER": "María Natalia Oña",
        "MANAGER": "María Natalia Oña"
    },
    {
        "NOMBRE": "Maria Natalia",
        "APELLIDO": "Oña",
        "EQUIPO": "ALPHA SYNERGY",
        "N° DE SERIE": 1117,
        "CUIDAD": "ROSARIO",
        "PROVINCIA": "SANTA FE",
        "OWNER": "María Natalia Oña",
        "MANAGER": "María Natalia Oña"
    },
    {
        "NOMBRE": "Maria Natalia",
        "APELLIDO": "Oña",
        "EQUIPO": "ALPHA SYNERGY",
        "N° DE SERIE": 1135,
        "CUIDAD": "ROSARIO",
        "PROVINCIA": "SANTA FE",
        "OWNER": "María Natalia Oña",
        "MANAGER": "María Natalia Oña"
    },
    {
        "NOMBRE": "Maria Natalia",
        "APELLIDO": "Oña",
        "EQUIPO": "BOLT THREE",
        "N° DE SERIE": 1053,
        "CUIDAD": "ROSARIO",
        "PROVINCIA": "SANTA FE",
        "OWNER": "María Natalia Oña",
        "MANAGER": "María Natalia Oña"
    },
    {
        "NOMBRE": "Maria Natalia",
        "APELLIDO": "Oña",
        "EQUIPO": "HIFU 7D",
        "N° DE SERIE": 1052,
        "CUIDAD": "ROSARIO",
        "PROVINCIA": "SANTA FE",
        "OWNER": "María Natalia Oña",
        "MANAGER": "María Natalia Oña"
    },
    null,
    {
        "NOMBRE": "Martin ",
        "APELLIDO": "Merino",
        "EQUIPO": "Zephyr Evolution",
        "N° DE SERIE": 505010081,
        "CUIDAD": "CABA",
        "PROVINCIA": "Buenos Aires",
        "OWNER": "Martin Merino",
        "MANAGER": "Martin Merino"
    },
    {
        "NOMBRE": "Martin ",
        "APELLIDO": "Merino",
        "EQUIPO": "HIFU 7D",
        "N° DE SERIE": 1003,
        "CUIDAD": "CABA",
        "PROVINCIA": "Buenos Aires",
        "OWNER": "Martin Merino",
        "MANAGER": "Martin Merino"
    },
    {
        "NOMBRE": "Martin ",
        "APELLIDO": "Merino",
        "EQUIPO": "MIO UP ",
        "N° DE SERIE": 1742,
        "CUIDAD": "CABA",
        "PROVINCIA": "Buenos Aires",
        "OWNER": "Pablo Rival Merino",
        "MANAGER": "Martin Merino"
    },
    null,
    {
        "NOMBRE": "Estela ",
        "APELLIDO": "Kec",
        "EQUIPO": "BOLT THREE",
        "N° DE SERIE": 1051,
        "CUIDAD": "Corrientes",
        "PROVINCIA": "Corrientes",
        "OWNER": "JCB INGIENERIA INTEGRAL",
        "MANAGER": "Estela Kec"
    },
    {
        "NOMBRE": "Estela ",
        "APELLIDO": "Kec",
        "EQUIPO": "HIFU 7D ",
        "N° DE SERIE": 1093,
        "CUIDAD": "Corrientes",
        "PROVINCIA": "Corrientes",
        "OWNER": "JCB INGIENERIA INTEGRAL",
        "MANAGER": "Estela Kec"
    },
    null,
    {
        "NOMBRE": "Karina ",
        "APELLIDO": "Garofolo",
        "EQUIPO": "HIFU 7D",
        "N° DE SERIE": 508011476,
        "CUIDAD": "General Cabrera",
        "PROVINCIA": "Cordoba",
        "OWNER": "Raul Felix Bricchi",
        "MANAGER": "Carina Garofolo"
    },
    {
        "NOMBRE": "Karina ",
        "APELLIDO": "Garofolo",
        "EQUIPO": "MIO UP",
        "N° DE SERIE": 1822,
        "CUIDAD": "General Cabrera",
        "PROVINCIA": "Cordoba",
        "OWNER": "Raul Felix Bricchi",
        "MANAGER": "Carina Garofolo"
    },
    null,
    {
        "NOMBRE": "Ricardo",
        "APELLIDO": "Ramallo ",
        "EQUIPO": "Mio UP",
        "N° DE SERIE": 1052,
        "CUIDAD": "Cordoba",
        "PROVINCIA": "Cordoba",
        "OWNER": "Ricardo Ramallo",
        "MANAGER": "Ricardo Ramallo"
    },
    {
        "NOMBRE": "Ricardo",
        "APELLIDO": "Ramallo ",
        "EQUIPO": "HIFU 7D",
        "N° DE SERIE": 1113,
        "CUIDAD": "Cordoba",
        "PROVINCIA": "Cordoba",
        "OWNER": "Gianre Roberto Silvio",
        "MANAGER": "Ricardo Ramallo"
    },
    {
        "NOMBRE": "Ricardo",
        "APELLIDO": "Ramallo ",
        "EQUIPO": "ALPHA SYNERGY",
        "N° DE SERIE": 1076,
        "CUIDAD": "Cordoba",
        "PROVINCIA": "Cordoba",
        "OWNER": "Ricardo Ramallo",
        "MANAGER": "Ricardo Ramallo"
    },
    null,
    {
        "NOMBRE": "Florencia",
        "APELLIDO": "Gonzalez",
        "EQUIPO": "HIFU 7D",
        "N° DE SERIE": 1098,
        "CUIDAD": "Neuquen",
        "PROVINCIA": "Neuquen",
        "OWNER": "Gabriel Gustavo Gonzalez",
        "MANAGER": "Florencia Gonzalez"
    },
    {
        "NOMBRE": "Florencia",
        "APELLIDO": "Gonzalez",
        "EQUIPO": "BOLT THREE",
        "N° DE SERIE": 1059,
        "CUIDAD": "Neuquen",
        "PROVINCIA": "Neuquen",
        "OWNER": "Gabriel Gustavo Gonzalez",
        "MANAGER": "Florencia Gonzalez"
    },
    null,
    {
        "NOMBRE": "Valeria",
        "APELLIDO": "Hercolini",
        "EQUIPO": "MIO UP",
        "N° DE SERIE": 1095,
        "CUIDAD": "Rosario",
        "PROVINCIA": "Santa Fe",
        "OWNER": "Daniel Hercolini",
        "MANAGER": "Valeria Hercolini"
    },
    {
        "NOMBRE": "Valeria",
        "APELLIDO": "Hercolini",
        "EQUIPO": "ALPHA SYNERGY",
        "N° DE SERIE": 1038,
        "CUIDAD": "Rosario",
        "PROVINCIA": "Santa Fe",
        "OWNER": "Daniel Hercolini",
        "MANAGER": "Valeria Hercolini"
    },
    null,
    {
        "NOMBRE": "Maria Rosa",
        "APELLIDO": "Stremel",
        "EQUIPO": "MIO UP ",
        "N° DE SERIE": 1094,
        "CUIDAD": "Winifreda",
        "PROVINCIA": "La Pampa",
        "OWNER": "Maria Rosa Stremel",
        "MANAGER": "Maria Rosa Stremel"
    },
    {
        "NOMBRE": "Maria Rosa",
        "APELLIDO": "Stremel",
        "EQUIPO": "ALPHA SYNERGY",
        "N° DE SERIE": 1050,
        "CUIDAD": "Winifreda",
        "PROVINCIA": "La Pampa",
        "OWNER": "Maria Rosa Stremel",
        "MANAGER": "Maria Rosa Stremel"
    },
    {
        "NOMBRE": "Maria Rosa",
        "APELLIDO": "Stremel",
        "EQUIPO": "HIFU 7D",
        "N° DE SERIE": 1036,
        "CUIDAD": "Winifreda",
        "PROVINCIA": "La Pampa",
        "OWNER": "Maria Rosa Stremel",
        "MANAGER": "Maria Rosa Stremel"
    },
    null,
    {
        "NOMBRE": "Cynthia Natalia",
        "APELLIDO": "Leiva",
        "EQUIPO": "Zephyr Evolution",
        "N° DE SERIE": 505010071,
        "CUIDAD": "Neuquen",
        "PROVINCIA": "Neuquen",
        "OWNER": "Cynthia Natalia Leyva",
        "MANAGER": "Cynthia Natalia Leiva"
    },
    {
        "NOMBRE": "Cynthia Natalia",
        "APELLIDO": "Leiva",
        "EQUIPO": "HIFU 7D",
        "N° DE SERIE": 1020,
        "CUIDAD": "Neuquen",
        "PROVINCIA": "Neuquen",
        "OWNER": "Cynthia Natalia Leyva",
        "MANAGER": "Cynthia Natalia Leiva"
    }
]