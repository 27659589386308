import React from 'react'
import logo from "../../../Utils/Assets/logoStarbeneAzul.png"

function Logo({size}) {
    return (
        <div className="text-center my-3">
            <img src={logo} width={size} />
        </div>
    )
}

export default Logo