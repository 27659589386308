import React, { useEffect, useState, useContext } from 'react'
import { useParams, useNavigate } from 'react-router-dom'
import { editarInfoCliente, obtenerDocumentos, agregarDocumento, eliminarDocumento, agregarAccion } from '../../../Utils/Firebase'
import { Table, Button } from "react-bootstrap";
import { AppContext } from '../../../Utils/Context'
import Navigator from '../../../Components/Navigator/Navigator';
import { obtenerImgEquipo } from '../../../Utils/Funciones';
import ButtonBack from '../../../Components/ButtonBack';
import EditorDeCampos from './EditorDeCampos';
import TituloPagina from "../../../Components/TituloPagina"
import BackMasTitulo from '../../../Components/BackMasTitulo';
import SelectorFecha from '../DetalleEquipo/SelectorFecha';

function DetalleCliente() {
    const navigate = useNavigate()
    const { id } = useParams()
    const { user } = useContext(AppContext)

    const [clientes, setClientes] = useState([])
    const [equipos, setEquipos] = useState([])
    const [cliente, setCliente] = useState(null)
    const [equiposCliente, setEquiposCliente] = useState([])
    const [inputEdicionTextos, setInputEdicionTextos] = useState(false)
    const [campoAEditar, setCampoAEditar] = useState("")
    const [nuevoValor, setNuevoValor] = useState("")
    const [campoAccion, setCampoAccion] = useState("")

    useEffect(() => {
        obtenerDocumentos("starbeneClients", setClientes)
        obtenerDocumentos("starbeneDynamicDevices", setEquipos)
    }, [])

    useEffect(() => {
        setCliente(clientes.find((cliente) => cliente.id == id))
    }, [clientes])

    useEffect(() => {
        obtenerEquiposDelCliente()
    }, [equipos, cliente])

    const obtenerEquiposDelCliente = () => {
        cliente && setEquiposCliente(equipos.filter((equipo) => equipo.deviceManager == cliente.clientFullName))
    }

    const confirmarEdicionDeCampo = () => {
        switch (campoAEditar) {
            case "Dirección del cliente":
                editarInfoCliente(cliente.id, "clientAddress", nuevoValor);
                break;
            case "Provincia del cliente":
                editarInfoCliente(cliente.id, "clientProvince", nuevoValor);
                break;
            case "Ciudad del cliente":
                editarInfoCliente(cliente.id, "clientCity", nuevoValor);
                break;
            case "Correo electrónico del cliente":
                editarInfoCliente(cliente.id, "clientMail", nuevoValor);
                break;
            case "Teléfono de contacto del cliente":
                editarInfoCliente(cliente.id, "clientPhoneContact", nuevoValor);
                break;
            case "País del cliente":
                editarInfoCliente(cliente.id, "clientCountry", nuevoValor);
                break;
            default:
                alert('Campo a editar no encontrado');
                break;
        }
    }

    const confirmarEliminarCliente = (id) => {
        eliminarDocumento('starbeneClients', id)
        agregarDocumento('Acciones', {
            accion: 'Cliente eliminado',
            nuevoValor: '',
            valorAnterior: cliente.clientFullName || cliente.clientMail || '',
            fecha: new Date(),
            responsable: user.email
        })
    }

    const [eliminarCliente, setEliminarCliente] = useState(false)

    // const agregarAccion = async () => {
    //     await agregarDocumento('Acciones', {
    //         accion: 'Edición de dato en cliente',
    //         cliente: cliente.clientFullName,
    //         fecha: new Date(),
    //         valorAnterior: campoAccion,
    //         nuevoValor: nuevoValor,
    //         responsable: user?.email
    //     })
    // }


    const handleBtnEditarCampo = async (campo, field) => {
        setCampoAEditar(campo)
        setInputEdicionTextos(true)
        setCampoAccion(field)
    }

    return (
        <div>
            <Navigator />

            {cliente &&

                <div className='col-10 mx-auto p-4 bg-blue mt-5 radius'>
                    <BackMasTitulo titulo={'Detalle de cliente'} />

                    <Table className='mt-3' striped hover>
                        <thead>
                            <tr className='text-light text-center'>
                                <th>Nombre completo</th>
                                <th>Dirección</th>
                                <th>País</th>
                                <th>Provincia</th>
                                <th>Ciudad</th>
                                <th>Mail</th>
                                <th>Contacto</th>
                                <th>Fecha de alta</th>
                                <th>Eliminar</th>
                            </tr>
                        </thead>

                        <tbody>
                            <tr className='text-center'>
                                <td className='text-light'>
                                    {cliente.clientFullName}
                                </td>

                                <td>
                                    <div className='text-light d-flex justify-content-evenly'>
                                        <div>
                                            {cliente.clientAddress}
                                        </div>
                                        <div style={{ cursor: 'pointer' }}
                                            onClick={() => handleBtnEditarCampo('Dirección del cliente', cliente.clientAddress)}>
                                            🖊
                                        </div>
                                    </div>
                                </td>

                                <td>
                                    <div className='text-light d-flex justify-content-evenly'>
                                        <div>
                                            {cliente.clientCountry}
                                        </div>
                                        <div style={{ cursor: 'pointer' }}
                                            onClick={() => handleBtnEditarCampo('País del cliente', cliente.clientAddress)}>
                                            🖊
                                        </div>
                                    </div>
                                </td>

                                <td>
                                    <div className='text-light d-flex justify-content-evenly'>
                                        <div>
                                            {cliente.clientProvince}
                                        </div>
                                        <div style={{ cursor: 'pointer' }}
                                            onClick={() => handleBtnEditarCampo('Provincia del cliente', cliente.clientProvince)}>
                                            🖊
                                        </div>
                                    </div>
                                </td>


                                <td>
                                    <div className='text-light d-flex justify-content-evenly'>
                                        <div>
                                            {cliente.clientCity}
                                        </div>
                                        <div style={{ cursor: 'pointer' }}
                                            onClick={() => handleBtnEditarCampo('Ciudad del cliente', cliente.clientCity)}>
                                            🖊
                                        </div>
                                    </div>
                                </td>

                                <td>
                                    <div className='text-light d-flex justify-content-evenly'>
                                        <div>
                                            {cliente.clientMail}
                                        </div>
                                        <div style={{ cursor: 'pointer' }}
                                            onClick={() => handleBtnEditarCampo('Correo electrónico del cliente', cliente.clientMail)}>
                                            🖊
                                        </div>
                                    </div>
                                </td>


                                <td>
                                    <div className='text-light d-flex justify-content-evenly'>
                                        <div>
                                            {cliente.clientPhoneContact}
                                        </div>
                                        <div style={{ cursor: 'pointer' }}
                                            onClick={() => handleBtnEditarCampo('Teléfono de contacto del cliente', cliente.clientPhoneContact)}>
                                            🖊
                                        </div>
                                    </div>
                                </td>

                                <td>
                                    <div className='text-light d-flex justify-content-evenly'>

                                        {
                                            !eliminarCliente ?
                                                <div>
                                                    <Button onClick={() => setEliminarCliente(!eliminarCliente)} variant='danger'>Eliminar</Button>
                                                </div> :
                                                <div>
                                                    <Button onClick={() => confirmarEliminarCliente(cliente.id)} className='me-1' variant='outline-success'>✅</Button>
                                                    <Button onClick={() => setEliminarCliente(!eliminarCliente)} variant='outline-danger'>❌</Button>
                                                </div>
                                        }
                                    </div>
                                </td>




                                <td className='text-light'>
                                    {cliente.clientDateOfSign}
                                </td>
                            </tr>
                        </tbody>
                    </Table>

                    {
                        inputEdicionTextos &&

                        <EditorDeCampos user={user} campoAEditar={campoAEditar} campoAccion={campoAccion} setInputEdicionTextos={setInputEdicionTextos} setNuevoValor={setNuevoValor} cliente={cliente} nuevoValor={nuevoValor} confirmarEdicionDeCampo={confirmarEdicionDeCampo} />

                    }

                    <div>
                        <h5 className='mt-4 text-center text-light'>Equipos de {cliente.clientFullName}</h5>
                        <div className='d-flex justify-content-center'>
                            {equiposCliente?.map((equipo, index) => {
                                return (
                                    <div key={index} className='bg-smoke p-4 text-center m-2 shadow radius'>
                                        <h5>{equipo.deviceName}</h5>

                                        <h6>Número de serie: <strong>{equipo.deviceSerialNumber}</strong></h6>

                                        <img src={obtenerImgEquipo(equipo)} width={100} style={{ borderRadius: 6, marginBottom: 8 }} />

                                        <h6>{!equipo.deviceWarrantyActivated ? "Garantía no activada ❌" : "Garantía activada correctamente ✅"}</h6>

                                        <Button variant='outline-primary' onClick={() => navigate(`/detalleequipo/${equipo.id}`)}>Ir al equipo</Button>
                                    </div>
                                )
                            })}
                        </div>
                    </div>
                </div>
            }
        </div>
    )
}

export default DetalleCliente