import React, { useEffect, useState } from 'react'
import { Form, Button, Table } from 'react-bootstrap';
import { eliminarDocumento, obtenerDocumentos } from '../../../Utils/Firebase';
import { actualizarDocumento } from '../../../Utils/Firebase';

function ListaAccesorios() {

    const [accesorios, setAccesorios] = useState([])
    const [search, setSearch] = useState("")
    const [modificador, setModificador] = useState(false)

    const [modal, setModal] = useState(false)
    const [nuevoNombre, setNuevoNombre] = useState(null)
    const [nuevoStock, setNuevoStock] = useState(null)
    const [nuevoPrecio, setNuevoPrecio] = useState(null)
    const [nuevaImagen, setNuevaImagen] = useState(null)

    const [nuevoDescuento, setNuevoDescuento] = useState(null)

    useEffect(() => {
        obtenerDocumentos("starbeneAccessories", setAccesorios)
    }, [])


    const colRef = "starbeneAccessories"


    const handleFileChange = (event) => {
        const file = event.target.files[0];

        if (file) {
            const reader = new FileReader();
            reader.onloadend = () => {
                setNuevaImagen(reader.result);
            };
            reader.readAsDataURL(file);
        }
    };

    const handleBtnModificar = async (id, field, value) => {
        try {
            await actualizarDocumento(colRef, id, field, value)
            setModificador(!modificador)
        } catch (error) {
            alert(error, "Comprimir imagen e intentar nuevamente")
        }
    }

    const handleBtnEliminarAccesorio = async (id) => {
        try {
            await eliminarDocumento(colRef, id)
            setModal(!modal)
            alert("Accesorio eliminado correctamente ✔")
        } catch (error) {
            alert(error)
        }
    }

    return (
        <div>

            <div className='mt-3 text-center col-5 mx-auto'>
                <Form.Group className="mb-3">
                    <Form.Control
                        type="text"
                        placeholder="Ingrese nombre del accesorio"
                        onChange={(e) => setSearch(e.target.value)}
                    />
                </Form.Group>
            </div>

            <p className='text-light text-center'><strong>*En el caso de que un accesorio no esté en descuento o se lo quiera sacar de promoción, se debera configurar el valor 0</strong></p>

            <div className='text-center mb-3'>
                <Button variant='dark' onClick={() => setModificador(!modificador)}>{!modificador ? "Abrir editor de información" : "Cerrar editor"}</Button>
            </div>

            <div className='col-10 mx-auto pb-4'>
                <Table striped hover>
                    <thead>
                        <tr className='text-light text-center'>
                            <th>Imagen</th>
                            <th>Nombre</th>
                            <th>Precio</th>
                            <th>Stock</th>
                            <th>Publicado</th>
                            <th>Promocionar</th>
                            <th>Eliminar</th>
                        </tr>
                    </thead>

                    {accesorios?.

                        filter((accesorio) => accesorio.name.toLowerCase().includes(search.toLowerCase()))

                        .map((accesorio, index) => {
                            return (
                                <tbody key={index}>
                                    <tr className='text-center'>
                                        <td className='text-dark'>
                                            <img src={accesorio.image} width="85" height="85" className='radius' />
                                            {
                                                modificador &&
                                                <div className='d-flex'>
                                                    <div>
                                                        <Form.Control type='file' onChange={handleFileChange} />
                                                    </div>
                                                    <div>
                                                        <Button
                                                            variant='primary'
                                                            className='mx-1'
                                                            onClick={() => handleBtnModificar(accesorio.id, "image", nuevaImagen)}>
                                                            <strong>✔</strong>
                                                        </Button>
                                                    </div>
                                                </div>
                                            }
                                        </td>

                                        <td className='text-light'>
                                            {
                                                accesorio.name
                                            }
                                            {
                                                modificador &&
                                                <div className='d-flex justify-content-center pt-3 align-items-end'>
                                                    <div>
                                                        <Form.Control defaultValue={accesorio.name} type='text' placeholder='Ingrese nuevo nombre' onChange={(e) => setNuevoNombre(e.target.value)} />
                                                    </div>
                                                    <div>
                                                        <Button variant='primary' onClick={() => { handleBtnModificar(accesorio.id, "name", nuevoNombre) }}><strong>✔</strong></Button>
                                                    </div>
                                                </div>
                                            }
                                        </td>

                                        <td style={{ color: "black", fontSize: 18 }}>
                                            ${
                                                accesorio.price
                                            }
                                            {
                                                modificador &&
                                                <div style={{ display: "flex", justifyContent: "center", marginTop: 10 }}>
                                                    <div>
                                                        <Form.Control onChange={(e) => setNuevoPrecio(e.target.value)} defaultValue={accesorio.price} type='number' placeholder='Ingrese nuevo precio' />
                                                    </div>
                                                    <div>
                                                        <Button variant='primary' onClick={() => { handleBtnModificar(accesorio.id, "price", nuevoPrecio) }}><strong>✔</strong></Button>
                                                    </div>
                                                </div>
                                            }
                                        </td>

                                        <td style={{ color: "black", fontSize: 18 }}>
                                            {
                                                accesorio.stock
                                            } u.
                                            {
                                                modificador &&
                                                <div style={{ display: "flex", justifyContent: "center", marginTop: 10 }}>
                                                    <div>
                                                        <Form.Control onChange={(e) => setNuevoStock(e.target.value)} defaultValue={accesorio.stock} type='number' placeholder='Ingrese nueva cantidad' />
                                                    </div>
                                                    <div>
                                                        <Button variant='primary' onClick={() => { handleBtnModificar(accesorio.id, "stock", nuevoStock) }}><strong>✔</strong></Button>
                                                    </div>
                                                </div>
                                            }
                                        </td>


                                        <td style={{ color: "black", fontSize: 18 }}>
                                            {accesorio.onSale ? "Publicado ✅" : "Inactivo ❌"}
                                            <div>
                                                {
                                                    modificador &&
                                                    <div className='mt-2'>
                                                        {
                                                            !accesorio.onSale
                                                                ?
                                                                <Button className="my-1" variant='success' onClick={() => { handleBtnModificar(accesorio.id, "onSale", true) }}>Activar</Button>
                                                                :
                                                                <Button className="my-1" variant='danger' onClick={() => { handleBtnModificar(accesorio.id, "onSale", false) }}>Desactivar</Button>
                                                        }
                                                    </div>
                                                }
                                            </div>
                                        </td>

                                        <td>
                                            {accesorio.descuento && accesorio.descuento}

                                            <div>
                                                {
                                                    modificador &&
                                                    <div style={{ display: "flex", justifyContent: "center", marginTop: 10 }}>
                                                        <div>
                                                            <Form.Control onChange={(e) => setNuevoDescuento(e.target.value)} defaultValue={accesorio.descuento || 0} type='number' placeholder='Ingrese nuevo porcentaje' />
                                                        </div>
                                                        <div>
                                                            <Button variant='primary' onClick={() => { handleBtnModificar(accesorio.id, "descuento", nuevoDescuento) }}><strong>✔</strong></Button>
                                                        </div>
                                                    </div>
                                                }
                                            </div>

                                        </td>

                                        <td>
                                            {
                                                !modal
                                                    ?
                                                    <Button variant='danger' onClick={() => setModal(true)}>Eliminar</Button>
                                                    :
                                                    <div className='d-flex justify-content-center pt-3'>
                                                        <Button className='mx-1' variant='success' onClick={() => handleBtnEliminarAccesorio(accesorio.id)}>Confirmar</Button>
                                                        <Button variant='danger' onClick={() => setModal(false)}>Cancelar</Button>
                                                    </div>
                                            }
                                        </td>
                                    </tr>
                                </tbody>
                            );
                        })}
                </Table>
            </div>
        </div>
    )
}

export default ListaAccesorios