import React from 'react'

function TituloPagina({ titulo }) {
    return (
            <h4 className='text-center text-light mt-2'>
                {titulo}
            </h4>
    )
}

export default TituloPagina