let myHeaders = new Headers();
myHeaders.append("Authorization", "Bearer TEST-4432814970075936-070511-342efe79e644c24e3a1782969fde095c-1415600316");

let requestOptions = {
    method: 'GET',
    headers: myHeaders,
    redirect: 'follow'
};


export const obtenerVentaMercadoPago = (id) => {
    fetch(`https://api.mercadopago.com/v1/payments/${id}`, requestOptions)
        .then(response => response.json())
        .then(result => console.log(result))
        .catch(error => console.log('error', error));
}