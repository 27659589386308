import React from 'react'
import { Button } from 'react-bootstrap'

function Botonera({setInputEditar, editarNoticia, setModalEliminar, noticia, inputEditar}) {
    return (
        <div className='pb-1 text-center mt-3'>

            <Button variant='primary' className='mx-1' onClick={() => setInputEditar(!inputEditar)}>Editar noticia</Button>
            {
                noticia && noticia.state == "Activa"
                    ?
                    <Button variant='danger' className='mx-1' onClick={() => editarNoticia(noticia.id, "state", "Inactiva")}>Desactivar noticia</Button>
                    :
                    <Button variant='success' className='mx-1' onClick={() => editarNoticia(noticia.id, "state", "Activa")}>Activar noticia</Button>
            }

            <Button variant='dark' className='mx-1' onClick={() => setModalEliminar(true)}>
                Eliminar noticia</Button>


        </div>
    )
}

export default Botonera