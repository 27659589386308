import alpha from "../Utils/Assets/alphaFoto1.png"
import bolt from "../Utils/Assets/boltFoto1.jpg"
import hifu from "../Utils/Assets/hifuFoto1.png"
import mio from "../Utils/Assets/mioUpFoto1.jpg"
import diamond from "../Utils/Assets/diamond.png"

export const obtenerNombreUsuario = (mail) => {
    if (mail == "kurt@starbene.com") {
        return "Kurt Rubinstein"
    } else if (mail == "santi@gmail.com") {
        return "Santiago Arbide"
    } else if (mail == "fran@gmail.com") {
        return "Francisco Celorio"
    } else if (mail == "alex@gmail.com") {
        return "Alejandro García"
    } else if (mail == "martin@gmail.com") {
        return "Martín Ferrari"
    }
}



export const obtenerImgEquipo = (equipo) => {
    if (equipo.deviceName == "Mio Up") {
        return mio
    } else if (equipo.deviceName == "Alpha Synergy") {
        return alpha
    } else if (equipo.deviceName == "Bolt Three") {
        return bolt
    } else if (equipo.deviceName == "Hifu 7D") {
        return hifu
    } else if (equipo.deviceName == "Diamond 9V") {
        return diamond
    }
}


export const sumarleUnAnio = (fecha) => {
    // Crear un objeto Date con el timestamp
    var fechaObjeto = new Date(fecha);

    // Sumar un año a la fecha
    fechaObjeto.setFullYear(fechaObjeto.getFullYear() + 1);

    // Función para agregar un cero delante de los números menores a 10
    const agregarCero = (numero) => {
        return numero < 10 ? '0' + numero : numero;
    };

    // Obtener día, mes y año
    var dia = agregarCero(fechaObjeto.getDate());
    var mes = agregarCero(fechaObjeto.getMonth() + 1); // getMonth() devuelve un índice basado en 0, por lo que se suma 1
    var año = fechaObjeto.getFullYear();

    // Formatear la fecha al estilo argentino
    return dia + '/' + mes + '/' + año;
};


export function convertirFechaAMilisegundos(fecha) {
    var partes = fecha.split('/');
    var dia = parseInt(partes[0], 10);
    var mes = parseInt(partes[1], 10) - 1; // Los meses en JavaScript van de 0 a 11
    var año = parseInt(partes[2], 10);

    var fechaObj = new Date(año, mes, dia);
    return fechaObj.getTime(); // Retorna la fecha en milisegundos
}


