import React, { useEffect, useState, useContext } from 'react'
import { useParams, useNavigate } from 'react-router-dom'
import { activarGarantiaDeEquipo, editarInfoEquipo, obtenerDocumentos, actualizarDocumento, agregarDocumento } from '../../../Utils/Firebase'
import { Table, Button, Form } from "react-bootstrap";
import Navigator from '../../../Components/Navigator/Navigator';
import { convertirFechaAMilisegundos, obtenerImgEquipo, sumarleUnAnio } from '../../../Utils/Funciones';
import { AppContext } from '../../../Utils/Context';
import EditorVencimientoGarantia from './EditorVencimientoGarantia';
import BackMasTitulo from "../../../Components/BackMasTitulo"
import { arrayUnion } from 'firebase/firestore';
import SelectorFecha from './SelectorFecha';
import DatePicker from "react-datepicker";

function DetalleEquipo() {
    const { id } = useParams()
    const { user } = useContext(AppContext)

    const navigate = useNavigate()

    const [modificarVencimiento, setModificarVencimiento] = useState(false)
    const [equipos, setEquipos] = useState([])
    const [equipo, setEquipo] = useState(null)
    const [clientes, setClientes] = useState([])
    const [modalGarantia, setModalGarantia] = useState(false)
    const [inputEdicionTextos, setInputEdicionTextos] = useState(false)
    const [campoAEditar, setCampoAEditar] = useState(null)
    const [nuevoValor, setNuevoValor] = useState("")
    const [nuevoClientId, setNuevoClientId] = useState(null)
    const [selectManager, setSelectManager] = useState(false)
    const [deviceManager, setDeviceManager] = useState(null)
    const [campoAccion, setCampoAccion] = useState("")
    const [fechaFinGarantia, setFechaFinGarantia] = useState(null)

    const [fechaInicio, setFechaInicio] = useState(new Date())

    const [selectServicio, setSelectServicio] = useState(false)
    const [nuevoEstadoServicio, setNuevoEstadoServicio] = useState('')

    const handleEditarEstadoServicio = async () => {
        await actualizarDocumento("starbeneDynamicDevices", id, "estadoServicio", nuevoEstadoServicio)
        await actualizarDocumento("starbeneDynamicDevices", id, "cambiosEstadoServicio", arrayUnion({ estado: nuevoEstadoServicio, fecha: new Date().getTime(), responsable: user?.email }))
        setSelectServicio(false)
    }


    useEffect(() => {
        const c = clientes?.find((cliente) => cliente.clientFullName == deviceManager)
        c && setNuevoClientId(c.id)
    }, [deviceManager])

    useEffect(() => {
        obtenerDocumentos("starbeneDynamicDevices", setEquipos)
        obtenerDocumentos("starbeneClients", setClientes)
    }, [])

    useEffect(() => {
        equipos && setEquipo(equipos.find((equipo) => equipo.id == id))
    }, [equipos])

    const confirmarEdicionDeCampo = () => {
        if (campoAEditar == "Número de serie del equipo") {
            editarInfoEquipo(equipo.id, "deviceSerialNumber", nuevoValor)
        } else if (campoAEditar == "Owner del equipo") {
            editarInfoEquipo(equipo.id, "deviceOwner", nuevoValor)
        } else if (campoAEditar == "Ubicación del equipo") {
            editarInfoEquipo(equipo.id, "deviceLocation", nuevoValor)
        } else if (campoAEditar == "Manager") {
            editarInfoEquipo(equipo.id, "deviceManager", nuevoValor)
            editarInfoEquipo(equipo.id, "clientId", nuevoClientId)
        }
    }

    const confirmInput = (id) => {
        actualizarDocumento("starbeneDynamicDevices", id, "deviceWarrantyEndDate", fechaFinGarantia)
        setModificarVencimiento(!modificarVencimiento)
    }

    const cancelInput = () => {
        setModificarVencimiento(!modificarVencimiento)
    }

    const handleBtnEditarCampo = async (campo, field) => {
        setCampoAEditar(campo)
        setInputEdicionTextos(true)
        setCampoAccion(field)
    }

    const handleConfirmarCambio = async () => {
        confirmarEdicionDeCampo()
        setInputEdicionTextos(false)
        await agregarDocumento('Acciones', {
            accion: 'Edición de dato en equipo',
            equipo: equipo.deviceName,
            nroEquipo: equipo.deviceSerialNumber,
            fecha: new Date(),
            valorAnterior: campoAccion,
            nuevoValor: nuevoValor,
            responsable: user?.email
        })
    }

    const handleConfirmarCambioManager = async () => {
        confirmarEdicionDeCampo()
        setSelectManager(false)
        await agregarDocumento('Acciones', {
            accion: 'Cambio de manager de equipo',
            equipo: equipo.deviceName,
            nroEquipo: equipo.deviceSerialNumber,
            fecha: new Date(),
            valorAnterior: campoAccion,
            nuevoValor: nuevoValor + " " + equipo.deviceSerialNumber,
            responsable: user?.email
        })
    }

    const handleConfirmarActivacionGarantia = async () => {
        activarGarantiaDeEquipo(equipo.id, convertirFechaAMilisegundos(fechaInicio))
        setModalGarantia(false)
        await agregarDocumento('Acciones', {
            accion: 'Garantía activada manualmente',
            nuevoValor: equipo.deviceName + " " + equipo.deviceSerialNumber,
            valorAnterior: '',
            fecha: new Date().getTime(),
            responsable: user.email
        })
    }

    const estadosDeEquipo = ['Cliente', 'Ingresado', 'Diagnosticado', 'En reparación', 'Reparado', 'Enviado']

    return (
        <div>
            <Navigator />
            {equipo &&
                <div>
                    <div className='col-11 mx-auto text-center bg-blue p-4 radius mt-5'>
                        <BackMasTitulo titulo={'Detalle de equipo'} />

                        <Table className='mt-2' striped hover>
                            <thead>
                                <tr className='text-light text-center'>
                                    <th>{equipo.deviceName}</th>
                                    <th>Número de serie</th>
                                    <th>Manager del equipo</th>
                                    <th>Owner del equipo</th>
                                    <th>Estado de la garantía</th>
                                    <th>Fechas de garantía</th>
                                    <th>{equipo.deviceWarrantyActivated == false ? 'Activar garantía' : ''}</th>
                                    <th>Fecha de alta</th>
                                    <th>Responsable de alta</th>
                                    <th>Estado del equipo</th>
                                </tr>
                            </thead>

                            <tbody>
                                <tr className='text-center'>
                                    <td>
                                        <div className='p-2'>
                                            <img src={obtenerImgEquipo(equipo)} width={75} className='radius' />
                                        </div>
                                    </td>


                                    <td>
                                        <div className='text-light d-flex justify-content-evenly'>
                                            <div>
                                                {equipo.deviceSerialNumber}
                                            </div>
                                            <div style={{ cursor: 'pointer' }}
                                                onClick={() => handleBtnEditarCampo('Número de serie del equipo', equipo.deviceSerialNumber)}
                                            >
                                                🖊
                                            </div>
                                        </div>
                                    </td>


                                    <td>
                                        <div className='text-light d-flex justify-content-evenly'>
                                            <div>
                                                {equipo.deviceManager ? equipo.deviceManager : ""}
                                            </div>
                                            <div
                                                style={{ cursor: 'pointer' }}
                                                onClick={() => {
                                                    setCampoAEditar("Manager")
                                                    setSelectManager(true)
                                                }}>
                                                🖊
                                            </div>
                                        </div>
                                    </td>

                                    <td>
                                        <div className='text-light d-flex justify-content-evenly'>
                                            <div>
                                                {equipo.deviceOwner}
                                            </div>
                                            <div style={{ cursor: 'pointer' }}
                                                onClick={() => handleBtnEditarCampo('Owner del equipo', equipo.deviceOwner)}
                                            >
                                                🖊
                                            </div>
                                        </div>
                                    </td>


                                    <td className='text-light'>
                                        {equipo.deviceWarrantyActivated
                                            ? "Activada ✅"
                                            : "Desactivada ❌"}
                                    </td>

                                    <td className='text-light'>
                                        {equipo.deviceWarrantyActivated
                                            ?
                                            <div>
                                                <span>Activación: {new Date(equipo.deviceWarrantyDate).toLocaleDateString()}</span><br />
                                                <span>Vencimiento: {equipo.deviceWarrantyEndDate || sumarleUnAnio(equipo.deviceWarrantyDate)}</span> <br />
                                                {
                                                    !modificarVencimiento &&
                                                    <Button className='mt-2' variant='primary' onClick={() => setModificarVencimiento(!modificarVencimiento)}>Modificar vencimiento</Button>
                                                }
                                                {
                                                    modificarVencimiento && <EditorVencimientoGarantia cancelInput={cancelInput} confirmInput={() => confirmInput(equipo.id)} setFechaFinGarantia={setFechaFinGarantia} />
                                                }
                                            </div>
                                            :
                                            "Desactivada ❌"}
                                    </td>


                                    <td className='text-light'>
                                        {
                                            !equipo.deviceWarrantyActivated
                                                ?
                                                <Button variant='success' onClick={() => setModalGarantia(true)}>Activar garantía</Button>
                                                : ''
                                        }
                                    </td>

                                    <td className='text-light'>
                                        {equipo.dateOfSigningDeviceOnCirculation}</td>

                                    <td className='text-light'>
                                        {equipo.deviceAddedBy}
                                    </td>

                                    <td className='text-light'>
                                        {equipo.estadoServicio && equipo.estadoServicio}
                                        <div style={{ cursor: 'pointer' }}
                                            onClick={() => setSelectServicio(true)}
                                        >
                                            🖊
                                        </div>
                                    </td>

                                </tr>
                            </tbody>
                        </Table>

                        <div>
                            <h4 className='text-light text-start mt-4'>Estado del servicio del equipo</h4>
                            <div>
                                {
                                    equipo.cambiosEstadoServicio?.map((cambio) => (
                                        <div className='text-light text-start mb-2'>
                                            <span>{cambio.estado}</span> ➡ <span>{new Date(cambio.fecha).toLocaleString()}</span> <span>🗝 {cambio.responsable}</span>
                                        </div>
                                    ))
                                }
                            </div>
                        </div>
                    </div>

                    {
                        inputEdicionTextos &&
                        <div>
                            <div className='text-center col-6 mx-auto bg-smoke p-4 radius'>

                                <h6>Escriba el nuevo valor de {campoAEditar}</h6>

                                <Form.Control type='text' onChange={(e) => setNuevoValor(e.target.value)}>
                                </Form.Control>

                                <div className='mt-2'>
                                    <Button className='mx-1' variant='success'
                                        onClick={handleConfirmarCambio}> Confirmar</Button>
                                    <Button className='mx-1' variant='danger' onClick={() => setInputEdicionTextos(false)}>Cancelar</Button>
                                </div>
                            </div>
                        </div>
                    }

                    {
                        selectServicio &&
                        <div className='text-center col-6 mx-auto bg-smoke p-4 radius'>
                            <Form.Select onChange={(e) => setNuevoEstadoServicio(e.target.value)}>
                                <option>Seleccionar estado del servicio</option>
                                {
                                    estadosDeEquipo.map((estado) => (
                                        <option value={estado}>{estado}</option>
                                    ))
                                }
                            </Form.Select>
                            <div className='mt-3'>
                                <Button onClick={() => handleEditarEstadoServicio()} variant='success'>Confirmar</Button>
                            </div>
                        </div>
                    }

                    {
                        selectManager &&
                        <div>
                            <div className='text-center col-6 mx-auto bg-smoke p-4 radius'>
                                <h6>Seleccione un nuevo manager para este equipo</h6>
                                <Form.Select onChange={(e) => {
                                    setNuevoValor(e.target.value)
                                    setDeviceManager(e.target.value)
                                }
                                }>
                                    <option>Seleccionar manager</option>
                                    {
                                        clientes && clientes.map((c) => {
                                            return (
                                                <option value={c.clientFullName}>
                                                    {c.clientFullName}
                                                </option>
                                            )
                                        })
                                    }

                                </Form.Select>

                                <div className='mt-2'>
                                    <Button
                                        className='mx-1'
                                        variant='success'
                                        onClick={handleConfirmarCambioManager}>Confirmar
                                    </Button>

                                    <Button
                                        className='mx-1'
                                        variant='danger'
                                        onClick={() => setSelectManager(false)}>Cancelar
                                    </Button>
                                </div>

                            </div>
                        </div>
                    }


                    {
                        modalGarantia &&
                        <>
                            <div className='text-center col-5 mx-auto p-5 radius shadow'>

                                <div>
                                    <h6 className='text-dark'>¿Activar manualmente la garantía de este equipo?</h6>

                                    <div className='col-8 mx-auto mb-3'>
                                        <Form.Control onChange={(e) => setFechaInicio(e.target.value)} type='text' placeholder='Fecha en el siguiente formato 7/11/2023 o 10/5/2023' />
                                    </div>

                                    {
                                        fechaInicio.length > 7 &&
                                        <Button
                                            className='mx-1'
                                            variant='success'
                                            onClick={handleConfirmarActivacionGarantia}>Confirmar
                                        </Button>
                                    }

                                    <Button className='mx-1' variant='danger' onClick={() => setModalGarantia(false)}>Cancelar</Button>
                                </div>
                            </div>
                        </>
                    }
                </div>
            }
        </div >
    )
}

export default DetalleEquipo