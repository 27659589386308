import React from 'react'
import { Button } from 'react-bootstrap'
import { useNavigate } from 'react-router-dom'

function BackMasTitulo({ titulo }) {

    const navigate = useNavigate()

    return (
        <div className='col-11 mx-auto mt-2 d-flex align-items-center'>
            <Button className='me-2' onClick={() => navigate(-1)} variant='light '><strong>⬅</strong></Button>
            <h2 className='text-center text-light mt-2'>
                {titulo}
            </h2>
        </div>
    )
}

export default BackMasTitulo