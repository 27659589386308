import React from 'react'
import { Button, Spinner } from 'react-bootstrap'

function LoginSpinner({ loading, }) {
    return (
        <div className='text-center mt-3'>
            {
                !loading &&
                <Button
                    variant="outline-primary"
                    type="submit">
                    Entrar
                </Button>
            }

            {
                loading &&
                <Spinner animation="border" role="status" style={{ color: "dark" }}>
                    <span className="visually-hidden">Loading...</span>
                </Spinner>
            }
        </div>)
}

export default LoginSpinner