import React, { useEffect, useState, useContext } from 'react'
import Navigator from '../../Components/Navigator/Navigator';
import { obtenerDocumentos } from '../../Utils/Firebase';
import { AppContext } from '../../Utils/Context';
import NuevoAccesorio from './Componentes/NuevoAccesorio';
import Ventas from '../../Components/Ventas';
import Botonera from './Componentes/Botonera';
import ListaAccesorios from './Componentes/ListaAccesorios';
import MySpinner from '../../Components/Spinner';
import BackMasTitulo from '../../Components/BackMasTitulo';

function Ecommerce() {

    const { user } = useContext(AppContext)

    const [accesorios, setAccesorios] = useState([])
    const [isFetching, setIsFetching] = useState(true)
    const [listaAccesorios, setListaAccesorios] = useState(true)
    const [gestorAccesorios, setGestorAccesorios] = useState(false)
    const [listaVentas, setListaVentas] = useState(false)


    useEffect(() => {
        obtenerDocumentos("starbeneAccessories", (data) => {
            setAccesorios(data);
            setIsFetching(false);
        });
    }, []);


    return (
        <div className='pb-3'>
            <Navigator />
            <div className='bg-blue p-4 col-11 mx-auto radius mt-5'>

                <BackMasTitulo titulo={'Ecommerce'} />

                <Botonera setGestorAccesorios={setGestorAccesorios} setListaVentas={setListaVentas} setListaAccesorios={setListaAccesorios} />

            </div>

            {
                !isFetching ?
                    <div style={{ backgroundColor: 'grey' }} className='col-11 mx-auto p-3 mt-4 radius mb-3'>

                        {
                            listaAccesorios && <ListaAccesorios />
                        }


                        {
                            gestorAccesorios &&
                            <NuevoAccesorio user={user} listaAccesorios={listaAccesorios} setListaAccesorios={setListaAccesorios} setGestorAccesorios={setGestorAccesorios} gestorAccesorios={gestorAccesorios} />
                        }

                        {
                            listaVentas &&
                            <Ventas user={user} />
                        }
                    </div>
                    :
                    <MySpinner />

            }

        </div >
    )
}

export default Ecommerce