import React, { useContext, useState } from 'react'
import Navigator from '../../Components/Navigator/Navigator'
import { AppContext } from '../../Utils/Context'
import { Button, Form, Spinner } from "react-bootstrap";
import { useNavigate } from 'react-router-dom'
import { agregarDocumento } from '../../Utils/Firebase';
import InputForm from './Componentes/InputForm';
import LoadingButton from './Componentes/LoadingButton';
import BackMasTitulo from '../../Components/BackMasTitulo';
import { clientes_app, form_nuevo_cliente } from '../../Utils/Extras';

function NuevoCliente() {

    const navigate = useNavigate()
    const { user } = useContext(AppContext)
    const [loading, setLoading] = useState(false)

    const [newClient, setNewClient] = useState({
        clientFullName: "",
        clientAddress: "",
        clientCity: "",
        clientProvince: "",
        clientCountry: "",
        clientMail: "",
        clientPhoneContact: "",
        clientSex: "",
        clientPassword: Date.now().toString().slice(6, 11),
        clientDateOfSign: new Date().toLocaleDateString(),
        clientAddedBy: user?.email,
    });

    const handleSubmit = async (e) => {
        e.preventDefault();
        setLoading(true);
        await agregarDocumento('starbeneClients', newClient)
        await agregarDocumento('Acciones', {
            accion: 'Nuevo cliente',
            nuevoValor: newClient.clientFullName,
            valorAnterior: '',
            fecha: new Date(),
            responsable: user.email
        })
        setLoading(false);
        navigate("/listaclientes");
    }

    return (
        <div>

            <Navigator />

            <div className='bg-blue shadow col-10 mx-auto text-center mt-5 p-4 radius' >

                <BackMasTitulo titulo={'Agregar nuevo cliente'} />

                <Form className='col-9 mx-auto pt-4 d-flex flex-wrap justify-content-center' >

                    {
                        form_nuevo_cliente.map((form) => (
                            <InputForm label={form.label} field={form.field} newClient={newClient} setNewClient={setNewClient} />
                        ))
                    }

                </Form>
                {
                    loading
                        ?
                        <Spinner animation="border" role="status">
                            <span className="visually-hidden">Loading...</span>
                        </Spinner>
                        :
                        <Button
                            variant="light"
                            className='my-2'
                            onClick={handleSubmit}
                        >
                            Agregar nuevo cliente
                        </Button>
                }
            </div>

        </div>
    )
}
export default NuevoCliente