import React, { useEffect, useState } from 'react'
import { obtenerDocumentos } from '../Utils/Firebase'
import { useNavigate } from 'react-router-dom'
import { Table, Button } from "react-bootstrap";
import Navigator from '../Components/Navigator/Navigator'
import MySpinner from '../Components/Spinner';
import Searcher from '../Components/Searcher';
import BackMasTitulo from '../Components/BackMasTitulo';
import { td_tabla_clientes, th_tabla_clientes } from '../Utils/Extras';
import { Icon } from '@iconify/react';

function ListaClientes() {
    const navigate = useNavigate()
    const [clientes, setClientes] = useState([])
    const [isFetching, setIsFetching] = useState(true);
    const [search, setSearch] = useState("")

    useEffect(() => {
        obtenerDocumentos("starbeneClients", (data) => {
            setClientes(data);
            setIsFetching(false);
        });
    }, []);


    return (
        <div>
            <Navigator />

            {
                !isFetching
                    ?
                    <div className='mt-5 col-10 mx-auto p-4 radius bg-blue'>

                        <BackMasTitulo titulo={'Lista de clientes'} />
                        <Searcher setSearch={setSearch} placeholder={"Buscar por nombre de cliente"} />

                        <div className='col-11 mx-auto mt-4'>
                            <Table hover>
                                <thead>
                                    <tr className='text-center text-light'>
                                        {
                                            th_tabla_clientes.map((th) => (
                                                <th>{th}</th>
                                            ))
                                        }
                                    </tr>
                                </thead>

                                {
                                    clientes?.

                                        filter((client) => client.clientFullName.toLowerCase().includes(search.toLowerCase()))

                                        .map((client, index) => {

                                            return (
                                                <tbody key={index}>
                                                    <tr className='text-center'>

                                                        {td_tabla_clientes.map((td) => (<td className='text-light'>{client[td]}</td>))}

                                                        <td>
                                                            <Button variant="outline-light" onClick={() => navigate(`/detallecliente/${client.id}`)}>
                                                                <Icon width={30} icon={'solar:user-broken'}/>
                                                            </Button>
                                                        </td>
                                                    </tr>
                                                </tbody>
                                            );
                                        })}
                            </Table>
                        </div>
                    </div>

                    :

                    <div className="mt-5">
                        <MySpinner />
                    </div>
            }
        </div>
    )
}

export default ListaClientes