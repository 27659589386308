import React, { useState } from 'react'
import { agregarDocumento } from '../../../Utils/Firebase'
import { Table, Button, Form } from "react-bootstrap";
import webp from "../../../Utils/Assets/webo.png"
import ButtonLoading from './ButtonLoading';


function NuevoAccesorio({ user, setGestorAccesorios, gestorAccesorios, setListaAccesorios, listaAccesorios }) {

    const [loading, setLoading] = useState(false)

    const [nuevoAccesorio, setNuevoAccesorio] = useState({
        name: "",
        description: "",
        stock: "",
        image: "",
    })

    const getImageInBase64 = (imagen) => {
        const reader = new FileReader();
        reader.readAsDataURL(imagen);
        reader.onload = function () {
            var base64 = reader.result;
            setNuevoAccesorio({
                ...nuevoAccesorio, image: base64
            })
        };
    };

    const handleBtnCargarNuevoAccesorio = async () => {
        setLoading(true)
        try {
            await agregarDocumento("starbeneAccessories", nuevoAccesorio)
            await agregarDocumento('Acciones', {
                accion: 'Nuevo accesorio',
                nuevoValor: nuevoAccesorio.name,
                valorAnterior: '',
                fecha: new Date(),
                responsable: user.email
            })
            alert("Accesorio subido correctamente ✔")
            setGestorAccesorios(!gestorAccesorios)
            setListaAccesorios(!listaAccesorios)
            setLoading(false)
        } catch (error) {
            alert(error, "Comprimir imagen y volver a intentar.")
        }
    }


    return (
        <div className='bg-smoke p-4 col-5 mx-auto radius mt-3'>
            <h5 className='text-center'>Cargar nuevo accesorio</h5>

            <Form.Control required type='text' placeholder='Ingrese nombre del nuevo accesorio' onChange={(e) => setNuevoAccesorio({ ...nuevoAccesorio, name: e.target.value })}></Form.Control>

            <Form.Control required type='number' placeholder='Ingrese precio' onChange={(e) => setNuevoAccesorio({ ...nuevoAccesorio, price: e.target.value })}></Form.Control>

            <Form.Control required type='number' placeholder='Ingrese stock inicial' onChange={(e) => setNuevoAccesorio({ ...nuevoAccesorio, stock: e.target.value })}></Form.Control>

            <Form.Control required type='text' placeholder='Ingrese descripción' onChange={(e) => setNuevoAccesorio({ ...nuevoAccesorio, description: e.target.value })}></Form.Control>

            <div style={{ display: "flex", alignItems: "center", backgroundColor: "white", padding: 5, border: "solid 1px gainsboro", borderRadius: 6 }}>
                <div className='mx-1'>
                    <span>Cargar imagen de accesorio</span>
                </div>
                <div>
                    <Form.Control type="file" onChange={(e) => {
                        getImageInBase64(e.target.files[0])
                    }}
                    />
                </div>
            </div>

            <div className='my-2'>
                <span><strong>Achicar la imagen antes de cargar para mejorar el rendimiento de Starbene Plus</strong></span>
                <br />
                <span>Ingresar a <a href="https://squoosh.app/" target="_blank">Squoosh</a> y comprimir al formato <strong>WebP</strong></span>
                <div className='my-2 text-center'>
                    <img src={webp} width={250} className='radius' />
                </div>
            </div>

            <ButtonLoading loading={loading} handleBtnCargarNuevoAccesorio={handleBtnCargarNuevoAccesorio} />
        </div>
    )
}

export default NuevoAccesorio