import React from 'react'
import { Form } from 'react-bootstrap'

function InputForm({ label, field, setNewDevice, newDevice }) {
    return (

        <Form.Group className="m-2">
            <Form.Label className='text-light'>{label}</Form.Label>
            <Form.Control
                onChange={(e) =>
                    setNewDevice({
                        ...newDevice,
                        [field]: e.target.value,
                    })
                }
                required
                placeholder={`Ingrese ${label.toLowerCase()}`}
            />
        </Form.Group>

    )
}

export default InputForm