import React, { useEffect, useState } from 'react'
import { obtenerDocumentos } from '../../Utils/Firebase'
import { Form, FormControl, Button } from 'react-bootstrap'
import Dropdown from 'react-bootstrap/Dropdown';


function SelectFormManagers({ setDeviceManager, newDevice, setNewDevice }) {

    const [managers, setManagers] = useState([])
    const [buscadorPorNombre, setBuscadorPorNombre] = useState(false)
    const [search, setSearch] = useState("")
    const [selectedManager, setSelectedManager] = useState(null)

    useEffect(() => {
        obtenerDocumentos("starbeneClients", setManagers)
    }, [])


    const handleBtnManager = (manager) => {
        setNewDevice({ ...newDevice, deviceManager: manager.clientFullName, clientId: manager.id })
        setSelectedManager(manager)
        setBuscadorPorNombre(!buscadorPorNombre)
    }

    return (
        <Form.Group className="m-2">

            <Form.Label className='text-light'>Manager del equipo</Form.Label>


            <Dropdown>
                <Dropdown.Toggle variant="light" id="dropdown-basic">
                    {selectedManager ? selectedManager.clientFullName : 'Seleccionar manager'}
                </Dropdown.Toggle>

                <Dropdown.Menu className='p-2'>
                    <Form.Control type='text' onChange={(e) => setSearch(e.target.value)} />

                    <div style={{ maxHeight: 250, overflowY: 'scroll' }}>
                        {
                            managers.filter((manager) => manager.clientFullName.toLowerCase().includes(search.toLowerCase()))

                                .map((manager) => (
                                    <Dropdown.Item onClick={() => handleBtnManager(manager)}>{manager.clientFullName}</Dropdown.Item>
                                ))
                        }
                    </div>

                </Dropdown.Menu>
            </Dropdown>

        </Form.Group>
    )
}

export default SelectFormManagers