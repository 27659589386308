import React from 'react'
import { Button } from 'react-bootstrap'
import { useNavigate } from 'react-router-dom'

function Botonera({ setGestorAccesorios, setListaAccesorios }) {
    const navigate = useNavigate()

    return (
        <div className='text-center mt-4'>

            <Button
                className='mx-1'
                variant='light'
                onClick={() => {
                    setListaAccesorios(true)
                    setGestorAccesorios(false)
                }}>Ver accesorios publicados</Button>


            <Button
                className='mx-1'
                variant='light'
                onClick={() => {
                    setListaAccesorios(false)
                    setGestorAccesorios(true)
                }}>Cargar nuevo accesorio en Starbene Plus</Button>


            <Button className='mx-1' variant='light' onClick={() => navigate("/listaVentas")}>Lista de ventas</Button>

            <Button className='mx-1' variant='light' onClick={() => navigate("/promocionequipos")}>Equipos en promoción</Button>

        </div>
    )
}

export default Botonera