import Table from 'react-bootstrap/Table';
import { Button } from 'react-bootstrap';
import { actualizarDocumento } from '../../Utils/Firebase';
import { useContext } from 'react';
import { AppContext } from '../../Utils/Context';

function TablaVentas({ ventas }) {

    const { user } = useContext(AppContext)

    const obtenerPrecioTotal = (productos) => {
        let total = 0
        for (let producto of productos) {
            total += producto.amount * producto.price
        }
        return total
    }

    const colRef = "purchaseOrders"

    const handleBtnRegistrada = async (id, cliente) => {
        try {

            await actualizarDocumento(colRef, id, "registered", true)
            // await actualizarDocumento(colRef, id, "registeredBy", user && user.userName)
            // await actualizarDocumento(colRef, id, "starbeneClient", cliente)
            // await actualizarDocumento(colRef, id, "registeredDate", new Date().toLocaleString())

            alert("Venta registrada correctamente ✔")

        } catch (error) {
            alert(error)
        }
    }




    return (
        <div className='col-10 mx-auto pb-3'>
            <Table striped hover>
                <thead>
                    <tr className='text-light'>
                        <th>Fecha</th>
                        <th>Cliente</th>
                        <th>Productos</th>
                        <th>Método</th>
                        <th>Dirección</th>
                        <th>Id del pago</th>
                        <th>Acción</th>
                    </tr>
                </thead>
                <tbody>
                    {
                        ventas?.map((venta, index) => {
                            return (

                                <tr key={index}>
                                    <td>{new Date(venta.dateCreated).toLocaleString()}</td>
                                    <td>{venta.client.fullName}</td>
                                    <td>

                                        {
                                            venta.cart.map((producto, index) => {
                                                return (
                                                    <div key={index} className='d-flex align-items-center p-1 m-2 radius bg-smoke'>
                                                        <img src={producto.image} width={65} height={65} />
                                                        <span>{producto.product} <strong>x{producto.amount}</strong> = </span>
                                                        <span className='mx-2'>${producto.price}</span>
                                                    </div>
                                                )
                                            })
                                        }

                                        <span className='mx-2'><strong>Precio total: ${obtenerPrecioTotal(venta.cart)}</strong></span>


                                    </td>
                                    <td>{venta.delivery.method}</td>
                                    <td>
                                        <span>{venta.client.address.street} <br /> {venta.client.address.city} <br /> {venta.client.address.province} <br /> CP: {venta.client.address.postalCode} <br /> {venta.client.address.instructions}</span>
                                    </td>
                                    <td>
                                        {venta.payment.id}
                                    </td>
                                    <td className='text-light'>
                                        {
                                            venta.registered ? 'Registrada correctamente' :
                                                <Button variant="success" onClick={() => {
                                                    handleBtnRegistrada(venta.id, venta.clienteStarbene)
                                                    // actualizarDocumento(colRef, venta.id, "saleResume", venta.compra )
                                                }

                                                }>Registrar</Button>
                                        }
                                    </td>
                                </tr>
                            )
                        })
                    }
                </tbody>
            </Table>
        </div>
    );
}

export default TablaVentas;