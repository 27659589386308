import React, { useEffect, useState } from 'react'
import Navigator from '../Components/Navigator/Navigator'
import { useNavigate } from 'react-router-dom'
import { obtenerDocumentos } from '../Utils/Firebase'
import { Table, Button } from "react-bootstrap";
import MySpinner from '../Components/Spinner';
import BackMasTitulo from '../Components/BackMasTitulo';
import Searcher from '../Components/Searcher';
import { td_tabla_equipos, th_tabla_equipos } from '../Utils/Extras';

function ListaEquipos() {
    const navigate = useNavigate()
    const [equipos, setEquipos] = useState([])
    const [search, setSearch] = useState("")
    const [isFetching, setIsFetching] = useState(true)


    useEffect(() => {
        obtenerDocumentos("starbeneDynamicDevices", (data) => {
            setEquipos(data);
            setIsFetching(false);
        });
    }, []);


    return (
        <div>
            <Navigator />
            {
                !isFetching
                    ?
                    <div className='mt-5 col-10 mx-auto p-4 radius bg-blue'>

                        <BackMasTitulo titulo={'Lista de equipos'} />
                        <Searcher setSearch={setSearch} placeholder="Buscar por nombre o número de serie del equipo" />

                        <div className='col-11 mx-auto mt-4'>
                            <Table hover>
                                <thead>
                                    <tr className='text-center text-light'> {th_tabla_equipos.map((th) => (<th>{th}</th>))}</tr>
                                </thead>

                                {equipos?.

                                    filter(
                                        (device) =>

                                            device.deviceSerialNumber.toString().includes(search) ||
                                            device.deviceOwner.toString().toLowerCase().includes(search.toLowerCase()) ||
                                            device.deviceName.toLowerCase().includes(search.toLowerCase()))

                                    .map((device, index) => {
                                        return (
                                            <tbody key={index}>

                                                <tr className='text-center'>
                                                    {td_tabla_equipos.map((td) => {
                                                        return (<td className='text-light'>{device[td]}</td>)
                                                    })}

                                                    <td className='text-light'>
                                                        {device.deviceWarrantyActivated
                                                            ? "Activada ✔"
                                                            : "Desactivada ❌"}
                                                    </td>

                                                    <td className='text-light'>
                                                        {device.deviceWarrantyActivated
                                                            ? new Date(device.deviceWarrantyDate).toLocaleDateString()
                                                            : "Desactivada ❌"}
                                                    </td>

                                                    <td>
                                                        <Button variant="outline-light" onClick={() => navigate(`/detalleequipo/${device.id}`)}>
                                                            Información
                                                        </Button>
                                                    </td>

                                                </tr>
                                            </tbody>
                                        );
                                    })}
                            </Table>
                        </div>
                    </div>
                    :
                    <div className="mt-5">
                        <MySpinner />
                    </div>
            }
        </div>
    )
}

export default ListaEquipos