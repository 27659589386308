import { BrowserRouter, Routes, Route } from "react-router-dom";
import "./App.css"
import Context from "./Utils/Context";
import Login from "./Pages/Login/Login";
import MenuPrincipal from "./Pages/MenuPrincipal/MenuPrincipal";
import NuevoCliente from "./Pages/NuevoCliente/NuevoCliente"
import ListaClientes from "./Pages/ListaClientes"
import ListaEquipos from "./Pages/ListaEquipos"
import Acciones from "./Pages/Acciones";
import Ecommerce from "./Pages/Ecommerce/Ecommerce";
import DetalleCliente from "./Pages/Detalles/DetalleCliente/DetalleCliente";
import DetalleEquipo from "./Pages/Detalles/DetalleEquipo/DetalleEquipo";
import DetalleNoticias from "./Pages/Detalles/DetalleNoticias";
import NuevaNoticia from "./Pages/Noticias/NuevaNoticia";
import Noticias from "./Pages/Noticias/Noticias";
import Pedidos from "./Pages/Pedidos";
import ListaVentas from "./Pages/ListaVentas/ListaVentas";
import NuevoEquipo from "./Pages/NuevoEquipo/NuevoEquipo";
import Notificaciones from "./Pages/Notificaciones/Notificaciones";
import PromocionEquipos from "./Pages/Ecommerce/PromocionEquipos";

function App() {
  return (
    <BrowserRouter>
      <Context>
        <Routes>
          <Route path="/" element={<Login />} />
          <Route path="/menuprincipal" element={<MenuPrincipal />} />
          <Route path="/nuevocliente" element={<NuevoCliente />} />
          <Route path="/nuevoequipo" element={<NuevoEquipo />} />
          <Route path="/listaclientes" element={<ListaClientes />} />
          <Route path="/listaequipos" element={<ListaEquipos />} />
          <Route path="/pedidos" element={<Pedidos />} />
          <Route path="/ecommerce" element={<Ecommerce />} />
          <Route path="/listaVentas" element={<ListaVentas />} />
          <Route path="/noticias" element={<Noticias />} />
          <Route path="/nuevaNoticia" element={<NuevaNoticia />} />
          <Route path="/noticias/:id" element={<DetalleNoticias />} />
          <Route path="/acciones" element={<Acciones />} />
          <Route path="/detallecliente/:id" element={<DetalleCliente />} />
          <Route path="/detalleequipo/:id" element={<DetalleEquipo />} />
          <Route path="/notificaciones" element={<Notificaciones />} />
          <Route path="/promocionequipos" element={<PromocionEquipos />} />
        </Routes>
      </Context>
    </BrowserRouter>
  );
}

export default App;
