import React, { useState, useEffect } from "react";
import { Table, Form, Button } from "react-bootstrap";
import { useNavigate } from "react-router-dom";
import { obtenerDocumentos } from "../../Utils/Firebase";
import Navigator from "../../Components/Navigator/Navigator"
import { editarNoticia } from "../../Utils/Firebase";
import TituloPagina from "../../Components/TituloPagina";
import MySpinner from "../../Components/Spinner";
import BackMasTitulo from "../../Components/BackMasTitulo";

function Noticias() {

    const [noticias, setNoticias] = useState([])
    const [search, setSearch] = useState("");
    const [isFetching, setIsFetching] = useState(true)
    const navigate = useNavigate();

    const [newNews, setNewNews] = useState({
        title: "",
        subtitle: "",
        content: "",
        date: Date.now().toString(),
        state: "",
    });

    const getImageInBase64 = (imagen) => {
        const reader = new FileReader();
        reader.readAsDataURL(imagen);
        reader.onload = function () {
            var base64 = reader.result;
            setNewNews({
                ...newNews,
                newsImage: base64,
            });
        };
    };


    useEffect(() => {
        obtenerDocumentos("starbeneNews", (data) => {
            setNoticias(data);
            setIsFetching(false);
        });
    }, []);


    const dateConverter = (timestamp) => {

        const date = new Date(parseInt(timestamp));
        const day = date.getDate();
        const month = date.getMonth() + 1;
        const year = date.getFullYear().toString().slice(-2);

        return `${day < 10 ? '0' + day : day}/${month < 10 ? '0' + month : month}/${year}`;
    };


    return (
        <div>
            <Navigator />

            {
                !isFetching
                    ?
                    <div style={{ minHeight: 750 }} className="col-10 mx-auto bg-blue p-4 radius mt-5">

                        <BackMasTitulo titulo={'Portal de noticias'} />
                        <div className="text-center">
                            <Button variant="light" onClick={() => navigate("/nuevanoticia")}>Agregar nueva noticia</Button>
                        </div>

                        <div className='mt-4 text-center col-5 mx-auto'>
                            <h5 className="text-light">Noticias existentes</h5>




                            <Form.Group className="mb-3">
                                <Form.Control
                                    type="text"
                                    placeholder="Ingrese título de la noticia"
                                    onChange={(e) => setSearch(e.target.value)}
                                />
                            </Form.Group>

                        </div>

                        <div>
                            <div className="col-10 mx-auto">
                                <Table striped bordered hover>
                                    <thead>
                                        <tr className="text-light text-center">
                                            <th>Título de la noticia</th>
                                            <th>Bajada</th>
                                            <th>Fecha de alta</th>
                                            <th>Estado actual</th>
                                            <th>Contenido</th>
                                        </tr>
                                    </thead>

                                    {noticias &&
                                        noticias
                                            .filter(
                                                (noticia) =>
                                                    noticia.title.toLowerCase().toString().includes(search.toLowerCase())
                                            )
                                            .map((news, i) => {
                                                return (
                                                    <tbody key={i}>
                                                        <tr>
                                                            <td className="text-light">{news.title}</td>
                                                            <td className="text-light">{news.subtitle}</td>
                                                            <td className="text-light">{dateConverter(news.date)}</td>
                                                            <td className="text-light text-center">
                                                                {news.state}
                                                                {
                                                                    news.state == "Activa"
                                                                        ?
                                                                        <Button variant="danger" onClick={() => editarNoticia(news.id, "state", "Inactiva")}>Desactivar</Button>
                                                                        :
                                                                        <Button variant="success" onClick={() => editarNoticia(news.id, "state", "Activa")}>Activar</Button>
                                                                }
                                                            </td>
                                                            <td><Button variant="outline-light" onClick={() => navigate(`/noticias/${news.id}`)}>Ver noticia completa</Button></td>
                                                        </tr>
                                                    </tbody>
                                                )
                                            })}
                                </Table>
                            </div>
                        </div>
                    </div>
                    :
                    <MySpinner />

            }



        </div>
    );
}

export default Noticias;
