import React from 'react'
import { Button } from 'react-bootstrap'
import { useNavigate } from 'react-router-dom'

function ButtonBack() {

    const navigate = useNavigate()

    return (
        <div className='me-2'>
            <Button onClick={() => navigate(-1)} variant='light '><strong>⬅</strong></Button>
        </div>
    )
}

export default ButtonBack