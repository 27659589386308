import React, { useState, useEffect } from 'react'
import Navigator from '../../Components/Navigator/Navigator'
import { Button } from 'react-bootstrap'
import { obtenerDocumentos } from '../../Utils/Firebase'
import TablaVentas from './TablaVentas'
import VentasRegistradas from './VentasRegistradas'
import ButtonBack from '../../Components/ButtonBack'
import BackMasTitulo from '../../Components/BackMasTitulo'


function ListaVentas() {

    const [ordenes, setOrdenes] = useState([])
    const [ventasConcretadas, setVentasConcretadas] = useState([])
    const [ventasCompletas, setVentasCompletas] = useState([])

    const [ventasRegistradas, setVentasRegistradas] = useState(false)

    useEffect(() => {
        // obtenerDocumentos("purchaseOrders", setOrdenes)
        obtenerDocumentos("purchaseOrders", setVentasCompletas)
    }, [])


    useEffect(() => {
        let tempList = []
        for (let venta of ventasConcretadas) {
            for (let orden of ordenes) {
                if (venta.paymentId == orden.payment.id && venta.status == "approved" && venta.statusDetail == "accredited")
                    tempList.push({
                        fecha: orden.dateCreated,
                        clienteStarbene: orden.client.fullName,
                        estado: venta.status,
                        compra: orden.cart,
                        idPago: venta.paymentId,
                        entrega: orden.delivery.method,
                        direccion: orden.client.address.street,
                        ciudad: orden.client.address.city,
                        provincia: orden.client.address.province,
                        instrucciones: orden.client.address.instructions,
                        codigoPostal: orden.client.address.postalCode,
                        id: venta.id,
                        registrada: venta.registered ? true : false
                    })
            }
        }
        setVentasCompletas(tempList)

    }, [ordenes, ventasConcretadas])


    return (
        <div>
            <Navigator />

            <div className="bg-blue col-11 mx-auto radius p-4 mt-5">
                <BackMasTitulo titulo={'Lista de ventas de accesorios'} />

                {/* <div className="text-center my-3">
                    <Button variant="light" onClick={() => setVentasRegistradas(!ventasRegistradas)}>

                        {
                            !ventasRegistradas
                                ?
                                "Ver ventas registradas"
                                :
                                "Pendientes de registrar"
                        }
                    </Button>
                </div> */}

                {/* {
                    ventasRegistradas &&
                    <VentasRegistradas ventas={ventasConcretadas} />
                } */}

                {
                    ventasCompletas &&
                    <TablaVentas ventas={ventasCompletas} />
                }
            </div>
        </div>
    )
}

export default ListaVentas