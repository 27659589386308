import React, { useEffect, useContext, useState } from 'react'
import Navigator from '../../Components/Navigator/Navigator'
import { AppContext } from '../../Utils/Context'
import { useNavigate } from 'react-router-dom'
import { agregarDocumento, obtenerDocumentos } from '../../Utils/Firebase'
import Form from "react-bootstrap/Form";
import InputForm from './InputForm'
import SelectFormEquipos from './SelectFormEquipos'
import SelectFormManagers from './SelectFormManagers'
import LoadingButton from './LoadingButton'
import MySpinner from '../../Components/Spinner'
import BackMasTitulo from '../../Components/BackMasTitulo'
import { form_nuevo_equipo } from '../../Utils/Extras'

function NuevoEquipo() {

    const navigate = useNavigate()
    const { user } = useContext(AppContext)
    const [loading, setLoading] = useState(false)
    const [clientes, setClientes] = useState([])
    const [deviceManager, setDeviceManager] = useState(null)
    const [isFetching, setIsFetching] = useState(true)

    useEffect(() => {
        obtenerDocumentos("starbeneClientes", (data) => {
            setClientes(data)
            setIsFetching(false)
        })
    }, [])

    const [newDevice, setNewDevice] = useState({
        deviceName: "",
        deviceManager: "",
        deviceSerialNumber: "",
        clientId: "",
        deviceObservations: "",
        deviceOwner: "",
        deviceLocation: "",
        deviceCityLocation: "",
        deviceWarrantyActivated: false,
        dateOfSigningDeviceOnCirculation: new Date().toLocaleDateString(),
        deviceAddedBy: user && user.email,
    });

    useEffect(() => {
        const c = clientes?.find((cliente) => cliente.clientFullName == deviceManager)
        c && setNewDevice({ ...newDevice, clientId: c.id })
    }, [deviceManager])

    const handleSubmit = async (e) => {
        e.preventDefault();
        setLoading(true);
        await agregarDocumento('starbeneDynamicDevices', newDevice)
        await agregarDocumento('Acciones', {
            accion: 'Nuevo equipo dinámico',
            nuevoValor: newDevice.deviceName + " " + newDevice.deviceSerialNumber,
            valorAnterior: '',
            fecha: new Date(),
            responsable: user.email
        })
        setLoading(false);
        navigate("/listaequipos");
    }

    return (
        <div>
            <Navigator />

            <div className='bg-blue shadow col-10 mx-auto text-center mt-5 p-4 radius' >


                <BackMasTitulo titulo={'Agregar nuevo equipo dinámico'} />

                {
                    !isFetching
                        ?
                        <Form className='col-5 mx-auto pt-4' onSubmit={handleSubmit}>

                            <div className="py-2">

                                <SelectFormEquipos newDevice={newDevice} setNewDevice={setNewDevice} />

                                <SelectFormManagers newDevice={newDevice} setDeviceManager={setDeviceManager} setNewDevice={setNewDevice} />

                            </div>

                            {
                                form_nuevo_equipo.map((form) => (
                                    <div className="py-2">
                                        <InputForm label={form.label} field={form.field} newDevice={newDevice} setNewDevice={setNewDevice} />
                                    </div>
                                ))
                            }

                            <div className="py-2">
                                <LoadingButton loading={loading} />
                            </div>

                        </Form>
                        :
                        <MySpinner />
                }

            </div>
        </div>
    )
}

export default NuevoEquipo