import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { Button } from "react-bootstrap";
import { signOut } from "firebase/auth"
import { auth } from "../../Utils/Firebase"
import logo from "../../Utils/Assets/logoStarbene.png"
import { botones_navigator } from "../../Utils/Extras";
import "./Navigator.css"

function Navigator() {

  const navigate = useNavigate();
  const handleLogOut = async () => {
    await signOut(auth)
    navigate("/")
  }

  const [selected, setSelected] = useState('')

  useEffect(() => {
    if (window.location.pathname.includes('listaclientes')) {
      setSelected('Lista de clientes')
    } else if (window.location.pathname.includes('listaequipos')) {
      setSelected('Lista de equipos')
    } else if (window.location.pathname.includes('nuevoequipo')) {
      setSelected('Nuevo equipo')
    } else if (window.location.pathname.includes('nuevocliente')) {
      setSelected('Nuevo cliente')
    } else if (window.location.pathname.includes('ecommerce')) {
      setSelected('Ecommerce')
    } else if (window.location.pathname.includes('noticias')) {
      setSelected('Noticias')
    }
    else {
      setSelected('Inicio')
    }
  }, [])

  return (
    <div>

      <div className="col-12 d-flex align-items-center" style={{ backgroundColor: 'blue' }}>

        <div className="col-1">
          <img onClick={() => navigate("/menuprincipal")} src={logo} width={200} className="pointer" />
        </div>

        <div className="col-10 d-flex justify-content-center">
          {
            botones_navigator.map((btn, index) => (
              <Button
                className="hover-btn nav mx-1"
                key={index}
                onClick={() => navigate(btn.navegacion)}
                variant="none"
                style={{ fontWeight: selected == btn.titulo && 'bolder' }}
              >
                {btn.titulo}
              </Button>
            ))
          }
        </div>

        <div className="col-1">
          <Button onClick={() => handleLogOut()} variant="light">Cerrar sesión</Button>
        </div>
      </div>

    </div>
  );
}

export default Navigator;