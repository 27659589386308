import React, { useEffect, useState } from 'react'
import { useParams, useNavigate } from 'react-router-dom'
import Navigator from '../../Components/Navigator/Navigator';
import { Button, Form } from "react-bootstrap";
import ButtonBack from "../../Components/ButtonBack"
import { actualizarDocumento, editarNoticia, eliminarDocumento, obtenerDocumentos } from '../../Utils/Firebase'
import "./DetalleNoticias.css"
import Botonera from '../Noticias/Botonera';

function DetalleNoticias() {
    const { id } = useParams()
    const navigate = useNavigate()
    const [noticias, setNoticias] = useState([])
    const [noticia, setNoticia] = useState({})
    const [modalEliminar, setModalEliminar] = useState(false)
    const [nuevaImagen, setNuevaImagen] = useState("")
    const [nuevoTitulo, setNuevoTitulo] = useState("")
    const [nuevaBajada, setNuevaBajada] = useState("")

    const [inputEditar, setInputEditar] = useState(false)

    useEffect(() => {
        obtenerDocumentos("starbeneNews", setNoticias)
    }, [])

    useEffect(() => {
        noticias && setNoticia(noticias.find((noticia) => noticia.id == id))
    }, [noticias])

    function verNoticia(html) {
        return <div className='news-content' dangerouslySetInnerHTML={{ __html: noticia && html }} />
    }

    const getImageInBase64 = (imagen) => {
        const reader = new FileReader();
        reader.readAsDataURL(imagen);
        reader.onload = function () {
            var base64 = reader.result;
            setNuevaImagen(base64)
        };
    };

    const handleBtnConfirmarCambio = (id, field, value) => {
        actualizarDocumento("starbeneNews", id, field, value)
        setInputEditar(!inputEditar)
    }


    return (
        <div className='pb-4'>
            <Navigator />

            <ButtonBack />

            <Botonera setInputEditar={setInputEditar} setModalEliminar={setModalEliminar} noticia={noticia} inputEditar={inputEditar} editarNoticia={editarNoticia} />


            {
                modalEliminar &&
                <div className='col-6 text-center bg-smoke p-4 radius mx-auto mt-3'>
                    <p><strong>¿Está seguro/a que desea eliminar esta noticia? La misma no podrá ser recuperada después.</strong></p>

                    <Button
                        variant='outline-success'
                        className='mx-1'
                        onClick={() => {
                            eliminarDocumento('starbeneNews', noticia.id)
                            navigate(-1)
                        }}>Confirmar</Button>

                    <Button variant='outline-danger' className='mx-1' onClick={() => setModalEliminar(false)}>Cancelar</Button>
                </div>
            }


            <div className='mt-3 text-center col-3 mx-auto bg-smoke radius p-3'>

                <div className='mt-3'>
                    <h4 className='p-2'>{noticia?.title}</h4>

                    {
                        inputEditar &&
                        <div>
                            <span className='text-danger'><strong>Nuevo título</strong></span>
                            <div className='d-flex p-2'>

                                <Form.Control className='mx-1' type='text' placeholder='Nuevo título' defaultValue={noticia.title} onChange={(e) => setNuevoTitulo(e.target.value)} />

                                <Button variant='success' onClick={() => handleBtnConfirmarCambio(noticia.id, "title", nuevoTitulo)}><strong>+</strong></Button>
                            </div>
                        </div>
                    }

                </div>

                {<img src={noticia?.image} width={380} className='p-1 mb-2 radius' />}

                {
                    inputEditar &&
                    <div>
                        <span className='text-danger'><strong>Nueva imagen</strong></span>

                        <div className='d-flex align-items-center'>

                            <Form.Control
                                className='m-2'
                                type="file"
                                onChange={(e) => getImageInBase64(e.target.files[0])}
                            />
                            <Button variant='success' onClick={() => handleBtnConfirmarCambio(noticia.id, "image", nuevaImagen)}><strong>+</strong></Button>

                        </div>
                    </div>
                }

                <div>
                    <p>{noticia?.subtitle}</p>
                    {
                        inputEditar &&
                        <div>
                            <span className='text-danger'><strong>Nueva bajada</strong></span>
                            <div className='d-flex p-2 align-items-center'>
                                <Form.Control className='mx-1' type='text' placeholder='Nueva bajada' defaultValue={noticia.subtitle} onChange={(e) => setNuevaBajada(e.target.value)} />
                                <Button variant='success' onClick={() => handleBtnConfirmarCambio(noticia.id, "subtitle", nuevaBajada)}><strong>+</strong></Button>
                            </div>
                        </div>
                    }
                </div>

                <div className="p-4">
                    <p>{noticia && verNoticia(noticia.content)}</p>
                </div>

            </div>
        </div>
    )
}

export default DetalleNoticias