import { initializeApp } from "firebase/app";
import { getDocs, getFirestore, updateDoc } from "firebase/firestore";
import { collection, onSnapshot, doc, addDoc, deleteDoc } from "firebase/firestore";
import { getDatabase } from "firebase/database";
import { getStorage, ref, uploadBytes, getDownloadURL } from "firebase/storage";
import { getAuth } from "firebase/auth"
import uuid from 'react-uuid';

const firebaseConfig = {
  apiKey: "AIzaSyBDj2siFVHgzVd3KVrO5KP5OSKLYQ7v3HU",
  authDomain: "starbeneplus.firebaseapp.com",
  projectId: "starbeneplus",
  storageBucket: "starbeneplus.appspot.com",
  messagingSenderId: "247560571808",
  appId: "1:247560571808:web:2081403d5d2f70203d72c5",
};

const app = initializeApp(firebaseConfig);
const storage = getStorage(app);
export const auth = getAuth(app)
export const db = getFirestore(app);
export const database = getDatabase(app);

export const obtenerDocumentos = (coleccion, setter) => {
  const colRef = collection(db, coleccion)
  onSnapshot(colRef, (q) => {
    const documentos = [];
    q.docs.forEach((doc) => {
      documentos.push({ ...doc.data(), id: doc.id });
    });
    setter(documentos);
  });
};

export const obtenerPedidos = (colRef, setter) => {
  onSnapshot(colRef, (q) => {
    const pedidos = [];
    q.docs.forEach((doc) => {
      pedidos.push({ ...doc.data(), id: doc.id });
    });
    setter(pedidos);
  });
};

export const agregarDocumento = async (coleccion, documento) => {
  await addDoc(collection(db, coleccion), documento)
}

export const agregarAccion = async (user, objeto, tipo, campo, valorAnterior, nuevoValor) => {
  await addDoc(collection(db, "Acciones"), {
    user: user,
    objeto: objeto,
    fecha: new Date().toLocaleString(),
    tipo: tipo,
    campo: campo,
    valorAnterior: valorAnterior,
    nuevoValor: nuevoValor
  })
}

export const agregarNoticia = async (noticia) => {
  await addDoc(collection(db, "starbeneNews"), noticia)
}

export const activarGarantiaDeEquipo = async (id, fecha) => {
  const ref = doc(db, "starbeneDynamicDevices", id)
  await updateDoc(ref, {
    deviceWarrantyActivated: true,
    deviceWarrantyDate: fecha
  })
}

export const editarInfoEquipo = async (id, field, value) => {
  const ref = doc(db, "starbeneDynamicDevices", id)
  await updateDoc(ref, {
    [field]: value
  })
}

export const editarInfoPedido = async (id, field, value) => {
  const ref = doc(db, "devicesOrders", id)
  await updateDoc(ref, {
    [field]: value
  })
}

export const actualizarDocumento = async (coleccion, id, field, value) => {
  const ref = doc(db, coleccion, id)
  await updateDoc(ref, {
    [field]: value
  })
}

export const editarInfoCliente = async (id, field, value) => {
  const ref = doc(db, "starbeneClients", id)
  await updateDoc(ref, {
    [field]: value
  })
}

export const editarNoticia = async (id, field, value) => {
  const ref = doc(db, "starbeneNews", id)
  await updateDoc(ref, {
    [field]: value
  })
}

export const eliminarDocumento = async (coleccion, id) => {
  await deleteDoc(doc(db, coleccion, id));
}

export const subirArchivo = async (file, id, user) => {
  const storageRef = ref(storage, uuid())
  await uploadBytes(storageRef, file)
  const url = await getDownloadURL(storageRef)
  agregarDocumento('ArchivosStorage', { id: id, documento: url, fecha: new Date(), nombre: file.name, user: user })
}


export const obtenerElementosQuery = async (q, setter) => {
  const querySnapshot = await getDocs(q)
  let datos = []
  querySnapshot.forEach((doc) => {
      datos.push({ ...doc.data(), id: doc.id });
  });
  setter(datos)
}
