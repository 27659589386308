import React from 'react'
import { Button, Spinner } from 'react-bootstrap'

function ButtonLoading({ loading, handleBtnCargarNuevoAccesorio }) {
    return (
        <div className="text-center">
            {
                loading
                    ?
                    <Spinner animation="border" role="status">
                        <span className="visually-hidden">Loading...</span>
                    </Spinner>
                    :
                    <Button
                        variant="success"
                        type="submit"
                        className='m-2'
                        onClick={handleBtnCargarNuevoAccesorio}
                    >
                        Agregar nuevo accesorio
                    </Button>
            }
        </div>
    )
}

export default ButtonLoading