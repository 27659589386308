import React, { useState, useContext } from "react";
import Navigator from '../../Components/Navigator/Navigator'
import ReactQuill from "react-quill";
import "react-quill/dist/quill.snow.css";
import { Button, Form } from "react-bootstrap";
import { agregarNoticia, agregarDocumento } from "../../Utils/Firebase";
import Spinner from "react-bootstrap/Spinner";
import { useNavigate } from "react-router-dom";
import webp from "../../Utils/Assets/webo.png"
import { AppContext } from "../../Utils/Context";
import BackMasTitulo from "../../Components/BackMasTitulo";

function NuevaNoticia() {

    const navigate = useNavigate()
    const { user } = useContext(AppContext)
    const [titulo, setTitulo] = useState("")
    const [subtitulo, setSubtitulo] = useState("")
    const [file, setFile] = useState(null)
    const [loading, setLoading] = useState(false)
    const [button, setButton] = useState(true)
    const [contenido, setContenido] = useState("")

    const getImageInBase64 = (imagen) => {
        const reader = new FileReader();
        reader.readAsDataURL(imagen);
        reader.onload = function () {
            var base64 = reader.result;
            setFile(base64)
        };
    };

    function verNoticia(html) {
        return <div className='news-content' dangerouslySetInnerHTML={{ __html: html }} />
    }

    const modules = {
        toolbar: [
            [{ header: [1, 2, false] }],
            ["bold", "italic", "underline", "strike", "blockquote"],
            [
                { list: "ordered" },
                { list: "bullet" },
                { indent: "-1" },
                { indent: "+1" },
            ],
            ["link", "image"],
            ["clean"],
        ],
    };

    return (
        <div>
            <Navigator />

            <div className="d-flex">
                <div className="col-8 mx-auto bg-smoke radius mt-5 p-4 bg-blue-low">


                    <BackMasTitulo titulo={'Agregar nueva noticia'} />


                    <div className="my-4">
                        <Form.Control className="my-1" type="text" placeholder="Ingrese título de la nueva noticia" onChange={(e) => setTitulo(e.target.value)}></Form.Control>
                        <Form.Control className="my-1" type="text" placeholder="Ingrese bajada de la nueva noticia" onChange={(e) => setSubtitulo(e.target.value)}></Form.Control>
                        <div className="mt-3 text-light">
                            <span>Agregar imagen de portada</span>
                            <Form.Control type="file" onChange={(e) => getImageInBase64(e.target.files[0])}
                            ></Form.Control>
                        </div>
                        <div className="text-light py-3">
                            <span><strong>Achicar la imagen antes de cargar para mejorar el rendimiento de Starbene Plus</strong></span>
                            <br />
                            <span>Ingresar a <a href="https://squoosh.app/" target="_blank">Squoosh</a> y comprimir al formato <strong>WebP</strong></span>
                            <div>
                                <img src={webp} width={250} style={{ borderRadius: 12 }} />
                            </div>
                        </div>
                    </div>

                    <div className="text-light">
                        <span>Agregar contenido de la noticia</span>
                        <ReactQuill
                            theme="snow"
                            // value={value}
                            onChange={(e) => setContenido(e)}
                            modules={modules}
                        />
                    </div>

                    {
                        button &&
                        <div className="text-center mt-2">
                            <Button variant="light" onClick={() => {
                                setButton(false)
                                setLoading(true)
                                /* ACÁ IRIA EL LLAMADO A LA API DE NOTIFCICACIONES */
                                agregarNoticia({
                                    title: titulo,
                                    subtitle: subtitulo,
                                    content: contenido,
                                    image: file,
                                    state: "Inactiva",
                                    date: Date.now().toString(),
                                }).then(() => {
                                    agregarDocumento('Acciones', {
                                        accion: 'Nueva noticia',
                                        nuevoValor: titulo,
                                        valorAnterior: '',
                                        fecha: new Date(),
                                        responsable: user.email
                                    })
                                    navigate("/noticias")
                                })
                            }
                            }
                            >Cargar nueva noticia</Button>
                        </div>
                    }

                    {
                        loading &&
                        <div className="text-center pt-2">
                            <Spinner animation="border" role="status" style={{ color: "lightblue" }}>
                                <span className="visually-hidden">Loading...</span>
                            </Spinner>
                        </div>
                    }
                </div>
                <div className="col-3" style={{ textAlign: "center", marginInline: "auto", backgroundColor: "whitesmoke", padding: 20, borderRadius: 40, minHeight: "600px", marginTop: 25, border: "solid 2px black", overflow: "auto", maxHeight: "800px" }}>
                    <h4>{titulo}</h4>
                    {<img src={file} width={300} style={{ padding: 5, borderRadius: 12 }} />}
                    <p><strong>{subtitulo}</strong></p>
                    <div style={{ maxWidth: "auto", height: "auto", overflow: "auto" }}>
                        {verNoticia(contenido)}
                    </div>
                </div>
            </div>

        </div>
    )
}

export default NuevaNoticia