import React, { useEffect, useContext, useState } from 'react'
import Navigator from '../Components/Navigator/Navigator'
import { AppContext } from '../Utils/Context'
import { editarInfoPedido, obtenerDocumentos } from '../Utils/Firebase'
import { Table, Button, Form } from "react-bootstrap";

function Pedidos() {
    const { user } = useContext(AppContext)
    const [pedidos, setPedidos] = useState([])
    const [clientes, setClientes] = useState([])
    const [search, setSearch] = useState("")
    const [commentValue, setCommentValue] = useState("")

    useEffect(() => {
        obtenerDocumentos("devicesOrders", setPedidos)
        obtenerDocumentos("starbeneClients", setClientes)
    }, [])

    const obtenerDatoCliente = (clientId, field) => {
        const nombre = clientes && clientes.find((cliente) => cliente.id == clientId)
        return nombre[field]
    }

    const handleBtnRegistrarPedido = (id) => {
        editarInfoPedido(id, "state", "Registrado")
        editarInfoPedido(id, "registeredDate", new Date().toLocaleDateString())
        editarInfoPedido(id, "responsable", user && user.userName)
    }

    return (
        <div>
            <Navigator />

            <div className='text-center mt-4'>
                <h4 className='text-light'>
                    Gestor de pedidos
                </h4>
            </div>

            <div className='mt-3 text-center col-5 mx-auto'>
                <Form.Group className="mb-3">
                    <Form.Control
                        type="text"
                        placeholder="Ingrese nombre del equipo"
                        onChange={(e) => setSearch(e.target.value)}
                    />
                </Form.Group>
            </div>

            <div className='col-11 mx-auto'>
                <Table striped hover>
                    <thead>
                        <tr className='text-center text-light'>
                            <th>Cliente</th>
                            <th>Teléfono</th>
                            <th>Mail</th>
                            <th>Equipo</th>
                            <th>Fecha</th>
                            <th>Prioridad</th>
                            <th>Acción</th>
                            <th>Resultado</th>
                            <th>Responsable</th>
                        </tr>
                    </thead>

                    {pedidos &&

                        pedidos

                            .filter((pedido) =>
                                pedido.device
                                    .toLowerCase()
                                    .includes(search.toLowerCase())
                            )

                            .map((pedido, index) => {
                                return (
                                    <tbody key={index}>
                                        <tr className='text-center'>
                                            <td className='text-light'>
                                                {clientes && obtenerDatoCliente(pedido.clientId, "clientFullName")}
                                            </td>
                                            <td className='text-light'>
                                                {clientes && obtenerDatoCliente(pedido.clientId, "clientPhoneContact")}
                                            </td>
                                            <td className='text-light'>
                                                {clientes && obtenerDatoCliente(pedido.clientId, "clientMail")}
                                            </td>
                                            <td className='text-light'>
                                                {pedido.device}
                                            </td>
                                            <td className='text-light'>
                                                {pedido.date}
                                            </td>
                                            <td className='text-light'>
                                                {pedido.highPriority ? "Alta" : "Media"}
                                            </td>

                                            <td className='text-light'>
                                                {
                                                    pedido.state ? pedido.state + " el día " + pedido.registeredDate :
                                                        <Button
                                                            variant="outline-light"
                                                            onClick={() => handleBtnRegistrarPedido(pedido.id)}>
                                                            Registrar
                                                        </Button>
                                                }
                                            </td>

                                            <td className='text-light'>
                                                {
                                                    pedido.comment ? pedido.comment :
                                                        <div className='d-flex justify-content-center'>

                                                            <div>
                                                                <Form.Control type='text' placeholder='Ingrese comentario' onChange={(e) => setCommentValue(e.target.value)} />
                                                            </div>
                                                            
                                                            <div>
                                                                <Button 
                                                                variant='primary' 
                                                                onClick={() => editarInfoPedido(pedido.id, "comment", commentValue)}><strong>+</strong></Button>
                                                            </div>

                                                        </div>
                                                }
                                            </td>

                                            <td className='text-light'>
                                                {pedido && pedido.responsable}
                                            </td>
                                        </tr>
                                    </tbody>
                                );
                            })}
                </Table>
            </div>


        </div>
    )
}

export default Pedidos