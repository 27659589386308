import React, { useEffect, useState, useContext } from 'react'
import Form from "react-bootstrap/Form";
import { signInWithEmailAndPassword } from 'firebase/auth';
import { auth } from '../../Utils/Firebase'
import { ToastContainer, toast } from 'react-toastify';
import { useNavigate } from 'react-router-dom';
import { AppContext } from '../../Utils/Context';
import LoginSpinner from './Componentes/LoginSpinner';
import { mensajeIngresoNoAutorizado } from '../../Utils/Extras';
import Logo from './Componentes/Logo';
import 'react-toastify/dist/ReactToastify.css';
import "./Login.css"

function Login() {

    const { user } = useContext(AppContext)
    const navigate = useNavigate()
    const [loading, setLoading] = useState(false)

    useEffect(() => {
        user && navigate("/menuprincipal")
    }, [user])


    const [userLog, setUserLog] = useState({ email: "", password: "" })

    const handleSubmit = async (e) => {
        e.preventDefault()
        setLoading(true)
        try {
            await signInWithEmailAndPassword(auth, userLog.email, userLog.password)
            navigate("/menuprincipal")
            setLoading(false)
        } catch (error) {
            setLoading(false)
            notify()
        }
    }

    const notify = () => toast(mensajeIngresoNoAutorizado);

    return (
        <div className='mt-5'>

            <div className='col-5 mx-auto radius p-5 shadow'>

                <Logo size={300}/>

                <Form onSubmit={handleSubmit}>

                    <Form.Group className="mb-2">
                        <Form.Control
                            className='text-center form'
                            required
                            type="email"
                            placeholder="Correo electrónico Starbene"
                            onChange={(e) => setUserLog({ ...userLog, email: e.target.value })}
                        />
                    </Form.Group>

                    <Form.Group className="mb-3">
                        <Form.Control
                            className='text-center form'
                            required
                            type="password"
                            placeholder="Contraseña"
                            onChange={(e) => setUserLog({ ...userLog, password: e.target.value })}
                        />
                    </Form.Group>


                    <LoginSpinner loading={loading} />

                </Form>

            </div>
            <ToastContainer />
        </div>
    )
}

export default Login