import React, { useState } from 'react'
import Navigator from '../../Components/Navigator/Navigator'
import Table from 'react-bootstrap/Table';
import BackMasTitulo from '../../Components/BackMasTitulo'
import "./Notificaciones.css"
import BotonesPrincipales from './BotonesPrincipales'
import { Button, Form, Spinner } from 'react-bootstrap'
import { db, obtenerElementosQuery } from '../../Utils/Firebase'
import { api, tipos } from './Back'
import { collection, getDocs, limit, orderBy, query } from 'firebase/firestore';

function Notificaciones() {

    const [tituloNotificacion, setTituloNotificacion] = useState('')
    const [textoNotificacion, setTextoNotificacion] = useState('')
    const [loading, setLoading] = useState(false)
    const [notificaciones, setNotificaciones] = useState([])
    const [selectedType, setSelectedType] = useState(null)
    const [elementos, setElementos] = useState([])
    const [elementId, setElementId] = useState('')

    const handleType = async (tipo) => {
        const tipoNotificacion = tipos.find((tipo_not) => tipo_not.tipo == tipo)
        if (tipoNotificacion?.query) {
            await obtenerElementosQuery(tipoNotificacion.query, setElementos)
        } else {
            setElementId('')
            setElementos([])
        }
        setSelectedType(tipoNotificacion)
    }

    const handleEnviarNotificacion = async () => {

        setLoading(true)
        var myHeaders = new Headers();
        myHeaders.append("Content-Type", "application/json");


        var raw = JSON.stringify({
            "title": tituloNotificacion,
            "body": textoNotificacion,
            "navigate": {
                "stack": selectedType.stack,
                "screen": selectedType.screen,
                "params": {
                    "id": elementId
                }
            },
            "sendToAll": true,
            "sendTo": [],
            "viewedBy": []
        });

        var requestOptions = {
            method: 'POST',
            headers: myHeaders,
            body: raw,
            redirect: 'follow'
        };

        await fetch(api, requestOptions)
            .then(response => {
                if (response.status == 200) {
                    alert('Notificación enviada exitosamente ✔')
                    setLoading(false)
                    setNuevaNotificacion(false)
                }
            })
            .catch(error => console.log('error', error));
    }

    const [nuevaNotificacion, setNuevaNotificacion] = useState(false)
    const [historialNotificacion, setHistorialNotificacion] = useState(false)

    const handleBtnHistorialNotificaciones = async () => {
        setNuevaNotificacion(false)
        await obtenerListaDeNotificaciones()
        setHistorialNotificacion(!historialNotificacion)
    }

    const handleBtnNuevaNotificacion = () => {
        setNuevaNotificacion(!nuevaNotificacion)
        setHistorialNotificacion(false)
    }

    const obtenerListaDeNotificaciones = async () => {
        const q = query(collection(db, "Notificaciones"), orderBy('date', 'desc'), limit(20))
        const querySnapshot = await getDocs(q)
        let datos = []
        querySnapshot.forEach((doc) => {
            datos.push({ ...doc.data(), id: doc.id });
        });
        setNotificaciones(datos)
    }

    return (
        <div>
            <Navigator />
            <div style={{ minHeight: 750 }} className="col-10 mx-auto bg-blue p-4 radius mt-5">
                <BackMasTitulo titulo={'Central de notificaciones'} />
                <BotonesPrincipales handleBtnHistorialNotificaciones={handleBtnHistorialNotificaciones} handleBtnNuevaNotificacion={handleBtnNuevaNotificacion} />
                <div>
                    {
                        nuevaNotificacion &&
                        <div className='col-5 mx-auto mt-3'>
                            <Form.Control className='mb-2' placeholder='Título de la notificación' onChange={(e) => setTituloNotificacion(e.target.value)} />
                            <Form.Control className='mb-2' placeholder='Texto de la notificación' onChange={(e) => setTextoNotificacion(e.target.value)} />
                            <Form.Select onChange={(e) => handleType(e.target.value)}>
                                <option>Seleccionar tipo</option>
                                {
                                    tipos.map((tipo) => (
                                        <option value={tipo.tipo}>{tipo.tipo}</option>
                                    ))
                                }
                            </Form.Select>

                            {
                                elementos.length > 0 &&
                                <Form.Select onChange={(e) => setElementId(e.target.value)} className='mt-2'>
                                    <option>Seleccionar elemento</option>
                                    {
                                        elementos?.map((elem) => (
                                            <option value={elem.id}>{elem[selectedType.key]}</option>
                                        ))
                                    }
                                </Form.Select>
                            }

                            {
                                !loading ?
                                    <div className="text-center mt-3">
                                        <Button onClick={handleEnviarNotificacion}>Enviar notificación</Button>
                                    </div>
                                    :
                                    <div className="text-center my-3">
                                        <Spinner animation="border" role="status">
                                            <span className="visually-hidden">Loading...</span>
                                        </Spinner>
                                    </div>
                            }



                        </div>
                    }

                    {
                        historialNotificacion &&
                        <Table className='mt-3 text-light' striped hover>
                            <thead>
                                <tr>
                                    <th>Título</th>
                                    <th>Enviada a todos</th>
                                    <th>Texto</th>
                                    <th>Fecha</th>
                                </tr>
                            </thead>
                            <tbody>

                                {
                                    notificaciones?.map((notif) => (
                                        <tr>
                                            <td>{notif.title}</td>
                                            <td>{notif.sendToAll ? '✔' : '❌'}</td>
                                            <td>{notif.body.slice(0, 40)}</td>
                                            <td>{new Date(notif.date).toLocaleDateString()}</td>
                                        </tr>

                                    ))
                                }

                            </tbody>
                        </Table>
                    }
                </div>
            </div>
        </div>
    )
}

export default Notificaciones