import { collection, query, where } from "firebase/firestore"
import { db } from "../../Utils/Firebase"

export const api = 'https://us-central1-starbeneplus.cloudfunctions.net/app/api/notifications'

export const tipos = [{
    tipo: 'Noticias',
    stack: "BlogStack",
    screen: "Blog"
},
{
    tipo: 'Catálogo de accesorios',
    screen: "CatalogoListaAccesorios",
    stack: "CatalogoStack"
},

{
    tipo: 'Catálogo de equipos',
    stack: "CatalogoStack",
    screen: "CatalogoListaEquipos"
},
{
    tipo: 'Generales',
    stack: "InicioStack",
    screen: "Inicio"
},
{
    tipo: 'Accesorio particular',
    stack: "CatalogoStack",
    screen: "CatalogoAccesorio",
    query: query(collection(db, "starbeneAccessories"), where('onSale', '==', true)),
    key: 'name'
},
{
    tipo: 'Equipo particular',
    stack: "CatalogoStack",
    screen: "CatalogoEquipo",
    query: query(collection(db, "starbeneGeneralDevices")),
    key: 'deviceName'
},
]

const miEquipo = {
    stack: "InicioStack",
    screen: "MiEquipo",
    id: ''
}

const blog = {
    stack: "BlogStack",
    screen: "Blog"
}



// Nota del Blog:
// - stack: "BlogStack",
//     - screen: "BlogPost"

const accesorios_catalogo = {
    screen: "CatalogoAccesorio",
    stack: "CatalogoStack"
}

const equipos_catalogo = {
    stack: "CatalogoStack",
    screen: "CatalogoEquipo"

}

const pefil = {
    stack: "CuentaStack",
    screen: "Cuenta"
}

// pedidos = {
//     stack: "CuentaStack",
//     screen: "CuentaMisPedidos"

// }
