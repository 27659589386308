import React, { useContext, useEffect, useState } from 'react'
import Navigator from '../../Components/Navigator/Navigator'
import BackMasTitulo from '../../Components/BackMasTitulo'
import { actualizarDocumento, agregarDocumento, obtenerDocumentos } from '../../Utils/Firebase'
import { Button } from 'react-bootstrap'
import { useNavigate } from 'react-router-dom'
import { AppContext } from '../../Utils/Context'

function PromocionEquipos() {

    const { user } = useContext(AppContext)

    const navigate = useNavigate()

    const [equipos, setEquipos] = useState([])

    useEffect(() => {
        obtenerDocumentos('starbeneGeneralDevices', setEquipos)
    }, [])


    const handleBtnDescuento = async (equipo) => {
        if (equipo.enDescuento) {
            await actualizarDocumento('starbeneGeneralDevices', equipo.id, 'enDescuento', false)
        } else {
            await actualizarDocumento('starbeneGeneralDevices', equipo.id, 'enDescuento', true)
            await actualizarDocumento('starbeneGeneralDevices', equipo.id, 'enDescuentoFecha', new Date())
            alert('El equipo fue registrado en promoción. ¡Vamos a avisarle a nuestros clientes!')
            await agregarDocumento('Acciones', {
                accion: 'Equipo registrado en promoción',
                nuevoValor: equipo.deviceName,
                valorAnterior: '',
                fecha: new Date(),
                responsable: user.email
            })
            navigate("/notificaciones")
        }
    }


    return (
        <div>
            <Navigator />
            <div className='bg-blue p-4 col-11 mx-auto radius mt-5'>
                <BackMasTitulo titulo={'Equipos en promoción'} />

                <p className='mt-2 col-8 mx-auto text-light'><strong>*Los equipos figurarán como que están en promoción, pero no se especificarán ni precios ni porcentajes. Para comunicar datos sobre la promoción, estos pueden ser detallados en el título o cuerpo de la notificación a enviar.</strong></p>

                <div className='d-flex flex-wrap mt-4 ms-5 justify-content-center'>
                    {
                        equipos?.map((equipo) => (
                            <div className='bg-smoke p-3 radius m-2 col-3 text-center'>
                                <div>
                                    <p>
                                        <strong>{equipo.deviceName}</strong>
                                    </p>
                                    {
                                        equipo?.enDescuentoFecha && equipo.enDescuento &&
                                        <p>En descuento desde: {equipo.enDescuentoFecha.toDate().toLocaleString()}</p>
                                    }
                                </div>
                                <div>
                                    <Button onClick={() => handleBtnDescuento(equipo)} variant={equipo.enDescuento ? 'danger' : 'success'}>{equipo.enDescuento ? 'Quitar descuento' : 'Promocionar equipo'}</Button>
                                </div>
                            </div>
                        ))
                    }
                </div>
            </div>
        </div>
    )
}

export default PromocionEquipos