import React from 'react'
import { Form, Button } from 'react-bootstrap'

function EditorVencimientoGarantia({cancelInput, confirmInput, setFechaFinGarantia}) {
    return (
        <div className='m-2 d-flex align-items-center'>
            <Form.Control className='mx-1' type='text' placeholder='Nueva fecha de vencimiento' onChange={(e) => setFechaFinGarantia(e.target.value)} />
            <Button className='mx-1' variant='success' onClick={confirmInput}><strong>+</strong></Button>
            <Button variant='danger' onClick={cancelInput}><strong>x</strong></Button>
        </div>
    )
}

export default EditorVencimientoGarantia